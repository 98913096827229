import { DialogProps } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import {
    ALL_ORGANIZATION_OPTIONS,
    ALL_STATUS_OPTIONS,
    Client,
    OrganizationOption,
    Project,
    User,
} from "../../../types";
import React, { useEffect, useState } from "react";
import ButtonSuccess from "../../Buttons/ButtonSuccess";
import ButtonDanger from "../../Buttons/ButtonDanger";
import { getServerUrl } from "../../../lib/utils";
import { useToast } from "../../../lib/toastToggler";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CapTimeDialog } from "../../CapTimeModal";
import ClientSearchDropdown from "./ClientSearchDropdown";
import { fetchFromServer } from "../../../lib/apiCalls/Commons";
import { useCurrentUserStore } from "../../../lib/currentUserSerivce";
import { Dropdown } from "primereact/dropdown";
import { getUnixTime } from "date-fns";
import { Checkbox } from "primereact/checkbox";
import ButtonInfo from "../../Buttons/ButtonInfo";
import ManagersSearchDropdown from "./ManagersSearchDropdown";

type Props = DialogProps & {
  setDialogOpen: (dialogState: boolean) => void;
  dialogProject: Project | null;
  isEditing: boolean;
  updateProject: (project: Project) => void;
  initClient?: Client;
  initOwner?: User[];
};

export function getEmptyProject(currentUser: User) {
    return {
        organizationName: "",
        name: "",
        projectCode: "",
        ownerIds: [currentUser.id],
        clientId: 0,
        id: 0,
        deletedAt: null,
    } as Project;
}

export function EditCreateProjectDialog(props: Props) {
  const queryClient = useQueryClient();
  const toast = useToast((state) => state.toast);
  const [currentProject, setCurrentProject] = useState<Project>();
  const currentUser = useCurrentUserStore((state) => state.currentUser);

  useEffect(() => {
    setCurrentProject({ ...props.dialogProject });
  }, [props.dialogProject]);

    useEffect(() => {
        if (!props.visible) {
            setAttemptedSubmit(false);
        }
    }, [props.visible]);

    const validateProjectName = (name: string) => {
        return name && name.trim() !== '';
    };

    const validateClient = (clientId: number | null | undefined) => {
        return clientId !== null && clientId !== undefined;
    };

    const validateOwner = (ownerIds: number[] | undefined) => {
        const checkAdmin = currentUser.roles.find((r) => r.name === "ADMIN");
        if (checkAdmin) {
            return ownerIds !== undefined && ownerIds.length > 0;
        } else {
            return ownerIds !== undefined && ownerIds.includes(currentUser.id);
        }
    };

    const [attemptedSubmit, setAttemptedSubmit] = useState(false);

    const saveProjectMutation = useMutation(
        ["saveProject"],
        async (currentProject: Project) => {
            const payload = {
                ...currentProject,
            };

            const resp = await fetchFromServer(
                getServerUrl("/projects-page/create-or-edit-project"),
                {
                    method: isEditing ? "PUT" : "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                }
            );

      if (resp.status >= 300) {
        toast(`${isEditing ? "Update" : "Create"} failed.`, "", "error");
         throw new Error("Received " + resp.status + " status code.");
      }
    },
    {
      onSuccess: async () => {
        toast(`${isEditing ? "Update" : "Create"} succeed.`, "", "success");
        await queryClient.invalidateQueries(["fetchProjectsPage"]);
        await queryClient.invalidateQueries(["authCheck"]);
        props.onHide();
      },
      onMutate: () => {
        toast(
          `${isEditing ? "Update" : "Create"} process started...`,
          "",
          "info"
        );
      },
        onError: (error: any) => {
          // TODO - change status after backend changes
            if (error.message.includes("404")) {
                toast(`Project with name ${currentProject.name} already exists`, "", "error");
            } else {
                toast(`${isEditing ? "Update" : "Create"} process failed.`, "", "error");
            }
        },
    }
    );

    const { isEditing, updateProject, dialogProject } = props;

    function accept() {
        setAttemptedSubmit(true);

        if (!currentProject.name || currentProject.name.trim() === '') {
            toast("Project name cannot be empty", "", "error");
            return;
        }

        if (!currentProject.ownerIds || currentProject.ownerIds.length === 0) {
            toast("Owner cannot be empty", "", "error");
            return;
        }

        if (!currentProject.clientId || currentProject.clientId === 0) {
            toast("Client cannot be empty", "", "error");
            return;
        }
        saveProjectMutation.mutate(currentProject);
    }

    return (
        <div className="flex">
            <CapTimeDialog
                {...props}
                className="w-[750px] h-[500px]"
                header={() => {
                    return (
                        <h1 className="text-2xl mb-5">
                            {props.isEditing ? "Edit project" : "New project"}
                        </h1>
                    );
                }
                }
                footer={() => {
                    return (
                        <div className="flex justify-end mr-12">
                            <ButtonSuccess
                                label={isEditing ? "Update" : "Create"}
                                onClick={accept}
                            />
                            <ButtonInfo
                                className="ml-6"
                                type="button"
                                label="Clear Project Form"
                                onClick={() => { updateProject(getEmptyProject(currentUser)) }} />
                            <div className="ml-2">
                                <ButtonDanger
                                    label="Cancel"
                                    onClick={() => {
                                        toast("", "Cancelled", "error");
                                        props.onHide();
                                    }}
                                />
                            </div>
                        </div>
                    );
                }}
            >
                <form className="flex flex-1 flex-col">
                    <div className="flex flex-1 flex-col">
                        <button
                            type="submit"
                            className="hidden"
                            onClick={(e) => {
                                e.preventDefault();
                                accept();
                            }}
                        />
                        <h1 className="mb-2">Organization name</h1>
                      <InputText
                        className=""
                        placeholder={"Select organization"}
                        value={currentProject?.organizationName ?? ""}
                        onChange={(e) => {
                          setCurrentProject({
                            ...currentProject,
                            organizationName: e.target.value,
                          });
                        }}
                      />
                        <h1 className="mb-2">Project name *</h1>
                        <InputText
                            className=""
                            value={currentProject?.name ?? dialogProject?.name ?? ""}
                            onChange={(e) => {
                                setCurrentProject({
                                    ...currentProject,
                                    name: e.target.value,
                                });
                            }}
                        />
                        {attemptedSubmit && !validateProjectName(currentProject.name) && (
                            <div className="error-message">Project name is required</div>
                        )}
                        <h1 className="mb-2">Project code</h1>
                        <InputText
                            className=""
                            value={currentProject?.projectCode ?? dialogProject?.projectCode ?? ""}
                            onChange={(e) => {
                                setCurrentProject({
                                    ...currentProject,
                                    projectCode: e.target.value,
                                });
                            }}
                        />
                        <>
                            <h1 className="mb-2">Manager *</h1>
                            <ManagersSearchDropdown
                                isEditing={isEditing}
                                loggedInUser={currentUser}
                                userRole={"MANAGER"}
                                placeholder={"Select owner"}
                                initUsers={props?.initOwner}
                                onUserChange={(users: User[]) => {
                                    setCurrentProject((currentProject) => ({
                                        ...currentProject,
                                        ownerIds: users ? users.map(user => user?.id) : []
                                    }));
                                }}
                                className="full-width"
                                showClear={true}
                            />
                            {attemptedSubmit && !validateOwner(currentProject.ownerIds) && (
                                <div className="error-message">Owner is required</div>
                            )}
                        </>


                        <h1 className="mb-2">Client *</h1>
                        <ClientSearchDropdown
                            placeholder={"Select client"}
                            initId={null}
                            initClient={props?.initClient}
                            onClientChange={(client: Client) => {
                                setCurrentProject((currentProject) => ({
                                    ...currentProject,
                                    clientId: client?.id,
                                }));
                            }}
                        />
                        {attemptedSubmit && !validateClient(currentProject.clientId) && (
                            <div className="error-message">Client name is required</div>
                        )}
                        <h1 className="mb-2">Order number</h1>
                        <InputText
                            className=""
                            value={
                                currentProject?.orderNumber ?? dialogProject?.orderNumber ?? ""
                            }
                            onChange={(e) => {
                                setCurrentProject({
                                    ...currentProject,
                                    orderNumber: e.target.value,
                                });
                            }}
                        />
                        <h1 className="mb-2">Status</h1>
                        <Dropdown
                            className=""
                            options={[...ALL_STATUS_OPTIONS.values()]}
                            placeholder="Project status"
                            value={currentProject?.status}
                            onChange={(e) => {
                                setCurrentProject((old) => {
                                    return {
                                        ...old,
                                        status: e.target.value,
                                        deletedAt: e.target.value == "active"
                                            ? null
                                            : getUnixTime(new Date()) * 1000,
                                    };
                                });
                            }}
                        />
                        <h1 className="mb-2">
                            <br />
                            Include in timesheet:{" "}
                            <Checkbox
                                className="w-[150px]"
                                checked={currentProject?.timesheet}
                                onChange={(e) =>
                                    setCurrentProject((old) => ({
                                        ...old,
                                        timesheet: e.checked,
                                    }))
                                }
                            />
                        </h1>
                        <h1 className="mb-2">
                            <br />
                            Is Internal:{" "}
                            <Checkbox
                                className="w-[150px]"
                                checked={currentProject?.isInternal}
                                onChange={(e) =>
                                    setCurrentProject((old) => ({
                                        ...old,
                                        isInternal: e.checked,
                                    }))
                                }
                            />
                        </h1>
                    </div>
                </form>
            </CapTimeDialog>
        </div>
    );
}
