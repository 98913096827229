import React from "react";
import ButtonSuccess from "../../Buttons/ButtonSuccess";
import ButtonDanger from "../../Buttons/ButtonDanger";
import ButtonInfo from "../../Buttons/ButtonInfo";
import {InputText} from "primereact/inputtext";
import {clientsPageStore} from "../../../pages/clients";
import {Client} from "../../../types";

const getEmptyClient = () => {
  return {
    id: 0,
    name: "",
    note: "",
    projectNames: null,
    deletedAt: null,
  } as Client;
};

type ToolbarContentProps = {
  setIsEditing: (boolean) => void;
  setDialogClient: (User) => void;
  setEditClientDialogOpen: (boolean) => void;
};

export const ClientsDatatableHeader = ({
  setIsEditing,
  setDialogClient,
  setEditClientDialogOpen,
}: ToolbarContentProps) => {

  return (
    <React.Fragment>
      <div className="flex flex-col w-full">
        <div className="flex justify-between my-3 mb-5">
          <div className="text-5xl">Manage clients</div>
          <div className="flex justify-center items-center">
            <div className="mr-3">
              <ButtonSuccess
                icon="pi pi-plus"
                label="NEW"
                onClick={() => {
                  setIsEditing(false);
                  setDialogClient(getEmptyClient());
                  setEditClientDialogOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
