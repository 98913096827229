import { Button, ButtonProps } from "primereact/button";
import { ReactElement } from "react";
import ButtonBase from "./ButtonBase";

export default function ButtonSuccess(
  props: ButtonProps
): ReactElement<Button> {
  const combinedClassName = `p-button-success ${props.className ?? ""}`;

  return <ButtonBase {...props} className={combinedClassName} />;
}
