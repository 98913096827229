import { DialogProps } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ALL_STATUS_OPTIONS, Client } from "../../../types";
import React, {useEffect, useRef, useState} from "react";
import ButtonSuccess from "../../Buttons/ButtonSuccess";
import ButtonDanger from "../../Buttons/ButtonDanger";
import { getServerUrl } from "../../../lib/utils";
import { useToast } from "../../../lib/toastToggler";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CapTimeDialog } from "../../CapTimeModal";
import { InputTextarea } from "primereact/inputtextarea";
import { fetchFromServer } from "../../../lib/apiCalls/Commons";
import { Dropdown } from "primereact/dropdown";
import { getUnixTime } from "date-fns";

type Props = DialogProps & {
  setDialogOpen: (dialogState: boolean) => void;
  dialogClient: Client | null;
  isEditing: boolean;
  updateClient: (client: Client) => void;
};

export function EditClientDialog(props: Props) {
  const queryClient = useQueryClient();
  const toast = useToast((state) => state.toast);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const validateClient = (clientName: string | null | undefined) => {
        return clientName !== null && clientName !== undefined && clientName.trim() !== '';
    };
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

    useEffect(() => {
        if (props.visible) {
            // Reset attemptedSubmit state when the dialog is opened
            setAttemptedSubmit(false);
        }
    }, [props.visible]);

    const saveClient = useMutation(
    ["saveClient"],
    async (dialogClient: Client) => {
      const payload = {
        ...dialogClient,
      };

      const resp = await fetchFromServer(getServerUrl("/clients-page"), {
        method: isEditing ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
      });

      if (resp.status >= 300) {
        toast(`${isEditing ? "Update" : "Create"} failed.`, "", "error");
        throw new Error("Received " + resp.status + " status code.");
      }
      return resp.json();
    },
    {
      onSuccess: async () => {
        toast(`${isEditing ? "Update" : "Create"} succeed.`, "", "success");
        await queryClient.invalidateQueries(["fetchClients"]);
      },
      onMutate: () => {
        toast(
          `${isEditing ? "Update" : "Create"} client process started...`,
          "",
          "info"
        );
      },
        onError: (error: any) => {
            // TODO - change status after backend changes
            if (error.message.includes("404")) {
                toast(`Client already exists`, "", "error");
            } else {
                toast(`${isEditing ? "Update" : "Create"} client process failed.`, "", "error");
            }
        },
    }
  );

  const { setDialogOpen, isEditing, updateClient, dialogClient } = props;

  function accept() {
      setAttemptedSubmit(true);
      if (!validateClient(dialogClient?.name)) {
          toast("Client name cannot be empty", "", "error");
          return;
      }
    saveClient.mutate(dialogClient);
    setDialogOpen(false);
  }

  return (
    <div className="flex" ref={modalRef}>
      <CapTimeDialog
        {...props}
        header={() => {
          return (
            <h1 className="text-2xl mb-5">
              {props.isEditing ? "Edit client" : "New client"}
            </h1>
          );
        }}
        className="w-[750px] h-[480px]"
        footer={() => {
          return (
            <div className="flex justify-end mr-12">
              <ButtonSuccess
                label={isEditing ? "Update" : "Create"}
                onClick={async () => {
                  accept();
                }}
              />
              <div className="ml-2">
                <ButtonDanger
                  label="Cancel"
                  onClick={() => {
                    toast("", "Cancelled", "error");
                    setDialogOpen(false);
                  }}
                />
              </div>
            </div>
          );
        }}
      >
        <form className="flex flex-1 flex-col">
          <button
            type="submit"
            className="hidden"
            onClick={(e) => {
              e.preventDefault();
              accept();
            }}
          />
          <h1 className="mb-2">Name *</h1>
          <InputText
            className=""
            value={dialogClient?.name ?? ""}
            onChange={(e) => {
              updateClient(
                dialogClient
                  ? {
                      ...dialogClient,
                      name: e.target.value,
                    }
                  : {
                      id: 0,
                      name: e.target.value,
                      note: "",
                    }
              );
            }}
          />
            {attemptedSubmit &&!validateClient(dialogClient?.name) && (
                <div className="error-message">Client name is required</div>
            )}
          <h1 className="mb-2">Note</h1>
          <InputTextarea
            className=""
            autoResize
            value={dialogClient?.note ?? ""}
            onChange={(e) => {
              updateClient(
                dialogClient
                  ? {
                      ...dialogClient,
                      note: e.target.value,
                    }
                  : {
                      name: "",
                      note: e.target.value,
                      id: 0,
                    }
              );
            }}
          />
          <h1 className="mb-2">Status</h1>
          <Dropdown
            className=""
            options={[...ALL_STATUS_OPTIONS.values()]}
            placeholder="User status"
            value={dialogClient?.deletedAt === null ? "active" : "inactive"}
            onChange={(e) =>
              updateClient({
                ...dialogClient,
                deletedAt: dialogClient?.deletedAt
                  ? null
                  : getUnixTime(new Date()) * 1000,
              })
            }
          />
        </form>
      </CapTimeDialog>
    </div>
  );
}
