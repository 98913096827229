import ButtonInfo from "./Buttons/ButtonInfo";
import { useCurrentUserStore } from "../lib/currentUserSerivce";
import { useEffect, useMemo, useState } from "react";
import { getServerUrl } from "../lib/utils";
import { useToast } from "../lib/toastToggler";
import { fetchFromServer } from "../lib/apiCalls/Commons";
import { DEFAULT_PAGE, queryClient } from "../appConfig";
import { SwitchThemeButton } from "./SwitchThemeButton";
import { Tooltip } from "primereact/tooltip";
import { useRouter } from "next/router";

export default function AppHeader({
  className
}: {
  className?: string;
  pageTitle: string;
}) {
  const currentUser = useCurrentUserStore((state) => state.currentUser);
  const router = useRouter();

  const [name, setName] = useState<string | null>(null);
  const [surname, setSurname] = useState<string | null>(null);

  const currentDisplayName = useMemo(() => {
    if (!name || !surname) return "Anonymous";
    return `${name} ${surname}`;
  }, [name, surname]);

  useEffect(() => {
    setName(currentUser?.name ?? null);
    setSurname(currentUser?.surname ?? null);
  }, [currentUser]);

  return (
    <div className={`flex justify-between ${className}`}>
      <img
        src="/logo.png"
        alt="hyper"
        className="w-[120px] m-3 cursor-pointer"
        onClick={async () => {
          await router.push(DEFAULT_PAGE);
        }}
      />
      <div className="flex justify-center items-center p-5 ">
        <Tooltip target="#display-name" position="bottom">
          <div>id: {currentUser.id}</div>
          <div>{currentUser.email}</div>
          {currentUser.roles.map((r) => (
            <div key={r.id}>{r.name}</div>
          ))}
        </Tooltip>
        <div id="display-name" className="text-xl mr-6 text-">
          {currentDisplayName}
        </div>
        <ButtonInfo
          label="Sign Out"
          icon="pi pi-user"
          className=""
          disabled={!currentUser}
          onClick={async () => {
            let success = true;
            let response;

            try {
              response = await fetchFromServer(getServerUrl("/verify/logout"), {
                credentials: "include",
              });
            } catch (e) {
              success = false;
              console.error(e);
            }

            await queryClient.invalidateQueries(["authCheck"]);

            if (!response?.ok || !success) {
              useToast.getState().toast("Logging out failed.", "", "error");
              return;
            }

            useToast.getState().toast("Logging out succeed.", "", "success");
          }}
        />
        <SwitchThemeButton className="ml-5 cursor-pointer hover:scale-105 hover:rotate-12" />
      </div>
    </div>
  );
}
