import {create} from "zustand";
import {ReactElement} from "react";
import {NullableDialogProps} from "../components/AppShell";

type ModalToggler = {
  toggleModal: () => void | null;
  setBody: (newBody: any) => void | null;
  setHeader: (newBody: any) => void | null;
  setProps: (props: NullableDialogProps) => void | null;
  init: (
    functionToSetHeader: (el: ReactElement) => void,
    functionToSetBody: (el: ReactElement) => void,
    functionToSetProps: (el: NullableDialogProps) => void,
    functionToToggleModal: () => void
  ) => void;
};

export const useModal = create<ModalToggler>()((set, getState, store) => ({
  setBody: null,
  toggleModal: null,
  setHeader: null,
  setProps: null,
  init: (
    functionToSetHeader: (el: ReactElement) => void,
    functionToSetBody: (el: ReactElement) => void,
    functionToSetProps: (el: NullableDialogProps) => void,
    functionToToggleModal: () => void
  ) => {
    set((state) => {
      return {
        ...state,
        setBody: functionToSetBody,
        setHeader: functionToSetHeader,
        toggleModal: functionToToggleModal,
        setProps: functionToSetProps,
      };
    });
  },
}));
