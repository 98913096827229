import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Toast, ToastSeverityType } from "primereact/toast";
import { useRouter } from "next/router";
import { DialogProps } from "primereact/dialog";
import CONST from "../const";
import { useToast } from "../lib/toastToggler";
import { useModal } from "../lib/modalToggler";
import { MsalProvider } from "@azure/msal-react";
import { CapTimeDialog } from "./CapTimeModal";
import AppHeader from "./Header";
import AppSideBar from "./AppSideBar";
import { appName, msalInstance, SIDEBAR_LINKS_MAP } from "../appConfig";
import {useCurrentUserStore, useThemeStore} from "../lib/currentUserSerivce";
import { ConfirmDialog } from "primereact/confirmdialog";

export type NullableDialogProps = Partial<Omit<DialogProps, "onHide">> & {
  onHide?(): void;
};

export const AppShell = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  const toastRef = useRef<Toast | null>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const [globalDialogOpen, setGlobalDialogOpen] = useState(false);
  const globalDialogBody = useRef<ReactElement>(<> </>);
  const globalDialogHeader = useRef<ReactElement>(<> </>);
  const globalDialogProps = useRef({} as NullableDialogProps);
  const currentUser = useCurrentUserStore((state) => state.currentUser);

  const currentPageCandidates = [...SIDEBAR_LINKS_MAP.keys()].filter((key) => {
    return router.asPath === SIDEBAR_LINKS_MAP.get(key);
  });

  const toggleGlobalModal = () => {
    setGlobalDialogOpen((old) => !old);
  };
  const setGlobalModalBody = (body: ReactElement) => {
    globalDialogBody.current = body;
  };
  const setGlobalModalHeader = (header: ReactElement) => {
    globalDialogHeader.current = header;
  };
  const setGlobalDialogProps = (props: DialogProps) => {
    globalDialogProps.current = props;
  };

  const showToast = (
    summary: string,
    details: string,
    serverity: ToastSeverityType,
    duration?: number
  ) => {
    toastRef.current.show({
      summary: summary,
      detail: details,
      life: duration ?? CONST.TOAST_LIFETIME_MS,
      severity: serverity,
    });
  };
  const currenPage = currentPageCandidates[0] ?? appName;


  useEffect(() => {
    useToast.getState().setToast(showToast);
    useModal
      .getState()
      .init(
        setGlobalModalHeader,
        setGlobalModalBody,
        setGlobalDialogProps,
        toggleGlobalModal
      );
  }, []);

  const childrenClassname = "";

  return (
    <MsalProvider instance={msalInstance}>
      <Toast ref={toastRef} position={"bottom-right"} />
      <ConfirmDialog />
      <CapTimeDialog
        onHide={() => setGlobalDialogOpen(false)}
        header={globalDialogHeader.current}
        visible={globalDialogOpen}
        {...globalDialogProps.current}
      >
        {globalDialogBody.current}
      </CapTimeDialog>

      <div className="flex flex-col overflow-auto h-screen">
        {currentUser  && (
          <AppHeader
            pageTitle={appName}
            className="flex shadow-xl aa h-[80px] overflow-y-auto"
          />
        )}

        <div className="flex flex-1 overflow-hidden" id="a">
          {currentUser  && (
            <AppSideBar
              className={"shadow-xl aa mt-1 flex flex-col overflow-y-auto"}
              currentPage={currenPage}
            />
          )}
          <div
            className={`${childrenClassname} m-1 flex-1 overflow-y-auto flex`}
            ref={divRef}
          >
            <div className="flex flex-1">{children}</div>
          </div>
        </div>
      </div>
    </MsalProvider>
  );
};
