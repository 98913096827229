import { ProjectDto } from "./typesDto";
import { ProjectTaskDto } from "./components/Tracker/TaskSearchDropdown";

export type User = {
    id: number;
    userOrganizationName: string | null;
    employeeNumber: string | null;
    name: string;
    surname: string;
    email: string;
    billingRatePLN: number;
    managerId?: number;
    roles: Role[];
    manager?: User | null;
    expType?: string;
    payroll?: string;
    updatedBy: User | null;
    updatedById?: number;
    createdAt: number | null;
    projectsIds?: number[];
    deletedAt: number | null;
    updatedAt: number | null;
    projectsWithManagerRights?: ProjectDto[];
    status?: string;
};

export type Role = {
    id: number;
    name: string;
    createdAt?: number;
    deletedAt?: number | null;
    updatedAt?: number | null;
};

export type UserGroupAssign = {
    id: number;
    groupId: number;
    userId: number;
};

export type UserGroup = {
    id: number;
    name: string;
    createdAt?: number;
    deletedAt?: number | null;
    updatedAt?: number | null;
};

export type ProjectUserAssign = {
    id: number;
    projectId: number;
    userId: number;
};

export type Client = {
    id: number;
    name: string;
    note: string;
    projectNames?: String[] | null;
    createdAt?: number;
    deletedAt?: number | null;
    updatedAt?: number | null;
    status?: string;
};


export type Project = {
    id: number;
    organizationName: string;
    name: string;
    projectCode: string;
    clientId: number;
    orderNumber?: string;
    timesheet?: boolean;
    ownerIds: number[];
    status?: string;
    deletedAt?: number;
    isInternal?: boolean;
};

export type Task = {
    id: number;
    name: string;
    description: string;
    project: Project | null;
    editedBy: User | null;
    createdAt?: number;
    deletedAt?: number | null;
    updatedAt?: number | null;
};

export type Tag = {
    id: number;
    name: string;
    createdAt?: number;
    deletedAt?: number | null;
    updatedAt?: number | null;
};

export type Tracker = {
    id: number;
    projectTrackerDTO: ProjectTracker[];
    date: Date;
    user: User;
};

export type ProjectTracker = {
    id: number;
    description: string;
    project: Project;
    task: Task;
    tags: Tag[];
    duration: number;
};

export const ALL_BILLING_OPTIONS = ["billable", "non-billable"] as const;
export type BillingOption = (typeof ALL_BILLING_OPTIONS)[number];

export const ALL_EXPTYPE_OPTIONS = ["RC_Time Std", "EXTSUB_Time Std"] as const;
export type ExpTypeOption = (typeof ALL_EXPTYPE_OPTIONS)[number];

export const ALL_PAYROLL_OPTIONS = ["Yes", "No"] as const;
export type PayrollOption = (typeof ALL_PAYROLL_OPTIONS)[number];

export const ALL_STATUS_OPTIONS = ["active", "inactive"] as const;

export const ALL_ORGANIZATION_OPTIONS = ["PL01T021_PBS", "PL01T022_BPM", "PL01T030_PMO_MGMT", "PL01T037_Integr"] as const;
export type OrganizationOption = (typeof ALL_ORGANIZATION_OPTIONS)[number];

export const ALL_TRACKER_EVENT_STATUSES = [
    "none",
    "locked",
    "accepted",
    "rejected",
] as const;
export type TrackerEventStatus = (typeof ALL_TRACKER_EVENT_STATUSES)[number];

export type TrackerEventDto = {
    id?: number;
    ownerId?: number;
    description: string;
    duration: number;
    tagsIds: number[];
    projectId?: number;
    isBillable?: boolean;
    isInternal?: boolean;
    overtime?: boolean;
    billingOption?: BillingOption;
    taskId?: number;
    projectCode?: string;
    date?: Date;
    onBehalfOfId?: number;
    status: TrackerEventStatus;
};

export type TrackersMetadata = {
    owner: {
        name: string;
        surname: string;
        email: string;
    };
    projects: {
        id: number;
        name: string,
        projectCode: string,
        deletedAt: number,
        isInternal: boolean,
        tasks: ProjectTaskDto[];
    }[];
};

export type TrackerPageResponse = {
    locks: TrackerEventLock[];
    trackers: TrackerEventDto[];
    weekSummaryInMinutes: number;
    monthSummaryInMinutes: number;
    error?: string;
};

export type TrackerEventLock = {
    userId?: number;
    projectId?: number;
    from: number;
    to: number;
};
