import React, {useEffect, useState} from "react";
import {Client, Project, User} from "../types";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {useToast} from "../lib/toastToggler";
import {EditCreateProjectDialog} from "../components/Popups/ProjectPopups/EditCreateProjectDialog";
import {fetchFromServer} from "../lib/apiCalls/Commons";
import {getServerUrl} from "../lib/utils";
import ButtonInfo from "../components/Buttons/ButtonInfo";
import {useRouter} from "next/router";
import {create} from "zustand";
import {ClientDto, ProjectDto, UserDto} from "../typesDto";
import {FilterMatchMode, FilterService} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {MultiSelect} from "primereact/multiselect";
import {Tag} from "primereact/tag";
import {confirmPopup, ConfirmPopup} from "primereact/confirmpopup";
import ProjectFileUploader from "./projects/ProjectFileUploader";

type ProjectPageFilters = {
  organizationName: string;
  name: string;
  projectCode: string,
  status: "any" | "active" | "inactive";
};

type ProjectPageStoreType = {
    projects: ProjectDto[];
    clients: ClientDto[];
    owners: UserDto[];
    setProjects: (projects: ProjectDto[]) => void;
    setClients: (clients: ClientDto[]) => void;
    setOwners: (owners: UserDto[]) => void;
    filters: ProjectPageFilters;
};

export const projectsStore = create<ProjectPageStoreType>()(
    (setState) => {
        const setProjects = (projects: ProjectDto[]) => {
            setState((old) => ({...old, projects: projects}));
        };

        const setClients = (clients: ClientDto[]) => {
            setState((old) => ({...old, clients}));
        };

        const setOwners = (owners: UserDto[]) => {
            setState((old) => ({...old, owners}));
        };

    return {
      setProjects,
      setOwners,
      setClients,
      owners: [],
      clients: [],
      projects: [],
      filters: {organizationName: "", name: "", projectCode: "", status: "any" },
    };
  }
);

export default function ProjectsPage() {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogProject, setDialogProject] = useState<Project | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const router = useRouter();
  const {toast} = useToast();
  const [projectNames, setProjectNames] = useState([]);
  const [organizationNames, setOrganizationNames] = useState([]);
  const [projectCodes, setProjectCodes] = useState([]);
  const [client, setClient] =  useState([]);
  const [ownerNames, setOwnerNames] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
  const [selectedProjectToAction, setSelectedProjectToAction] = useState(null);
  const [data, setData] = useState([]);
  function fetchData(){
      fetchFromServer(getServerUrl('/projects-page/allProjects'))
          .then((response) => response.json())
          .then((data) => {
              const processedData = data?.map(item => ({
                  ...item,
                  ownerNames: item.owners.map(owner => owner.name + " " + owner.surname),
                  status: item.deletedAt === null ? 'active' : 'inactive',
                  clientName: item.client.name

              }));
              setData(processedData);
              setOrganizationNames([...new Set(processedData.map((item) => item.organizationName))]);
              setProjectNames([...new Set(processedData.map((item) => item.name))]);
              setProjectCodes([...new Set(processedData.map((item) => item.projectCode))]);
              setClient([...new Set(processedData.map((item) => item.clientName))]);
              setOwnerNames([...new Set(processedData.flatMap((item) => item.ownerNames))]);
              setStatuses([...new Set(processedData.map((item) => item.status))]);
          });
  }

    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        FilterService.register('custom_ownerNames', (value, filter) => {
            if (filter) {
                return filter.every(v => value.includes(v));
            }
            return true;
        });
    }, []);

    async function deleteProject(projectId: number) {
        try {
            await deleteProjectApiCall(projectId);
            toast("Deletion succeed.", "1 item affected.", "success");
            fetchData();
        } catch (error) {
            toast("Deletion failed.", "No items affected.", "error");
        }
    }

    const updateProject = (newProject: Project) => {
        setDialogProject(newProject);
    };

    const getClientFromProjects = () => {
        return selectedProjectToAction?.data.client as unknown as Client;
    };

    const getOwnerFromProjects = () => {
        return selectedProjectToAction?.data.owners as unknown as User[];
    };

  const clearAllFilters = () => {
    let _filters = {
      global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      clientName: {value: null, matchMode: FilterMatchMode.EQUALS},
      name: {value: null, matchMode: FilterMatchMode.EQUALS, constraints: []},
      ownerNames: {value: null, matchMode: FilterMatchMode.CUSTOM},
      status: {value: null, matchMode: FilterMatchMode.EQUALS},
      organizationName: {value: null, matchMode: FilterMatchMode.EQUALS},
      projectCode: {value: null, matchMode: FilterMatchMode.EQUALS},
    };
    setGlobalFilterValue('');
    setFilters(_filters);
    setSortField(null);
    setSortOrder(null);
  };

    const [filters, setFilters] = useState({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        clientName: {value: null, matchMode: FilterMatchMode.EQUALS},
        name: {value: null, matchMode: FilterMatchMode.EQUALS, constraints: []},
        ownerNames: {value: null, matchMode: FilterMatchMode.CUSTOM},
        status: {value: null, matchMode: FilterMatchMode.EQUALS},
        organizationName: {value: null, matchMode: FilterMatchMode.EQUALS},
        projectCode: {value: null, matchMode: FilterMatchMode.EQUALS},
    });


    const statusItemTemplate = (option) => {
        return <Tag style={{width: '4rem'}} value={option} severity={getSeverityStatus(option)}/>;
    };
    const statusBodyTemplate = (rowData) => {
        return <Tag style={{width: '6rem'}} value={rowData.status} severity={getSeverityStatus(rowData.status)}/>;
    };
    const clearFilter = (filterName) => {
        let _filters = {...filters};
        _filters[filterName].value = null;
        setFilters(_filters);
    };


    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = {...filters};
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const projectNameFilter = (
        <div className="multiselect-container">
            <MultiSelect
                value={filters.name.value}
                options={projectNames}
                onChange={(e) => {
                    let _filters = {...filters};
                    _filters['name'].value = e.value;
                    _filters['name'].matchMode = FilterMatchMode.IN;
                    setFilters(_filters);
                }}
                filter placeholder="Projects"
                className={filters.name.value != null ? 'p-column-filter active-filter' : 'p-column-filter'}
            />
        </div>
    );

    const projectCodesNonNull = projectCodes.filter(code => code !== null);
    const projectCodeFilter = (
        <div className="multiselect-container">
            <MultiSelect
                value={filters.projectCode.value}
                options={projectCodesNonNull}
                onChange={(e) => {
                    let _filters = {...filters};
                    _filters['projectCode'].value = e.value;
                    _filters['projectCode'].matchMode = FilterMatchMode.IN;
                    setFilters(_filters);
                }}
                filter placeholder="Project code"
                className={filters.projectCode.value != null ? 'p-column-filter active-filter' : 'p-column-filter'}
            />
        </div>
    );

    const organizationNameNonNull = organizationNames.filter(organizationName => organizationName !== null);
    const organizationNameFilter = (
        <div className="multiselect-container">
            <MultiSelect
                value={filters.organizationName.value}
                options={organizationNameNonNull}
                onChange={(e) => {
                    let _filters = {...filters};
                    _filters['organizationName'].value = e.value;
                    _filters['organizationName'].matchMode = FilterMatchMode.IN;
                    setFilters(_filters);
                }}
                filter placeholder="Organization name"
                className={filters.organizationName.value != null ? 'p-column-filter active-filter' : 'p-column-filter'}
            />
        </div>
    );
    const clientRowFilterTemplate = (
        <div className="multiselect-container">
            <MultiSelect
                value={filters.clientName.value}
                options={client}
                onChange={(e) => {
                    let _filters = {...filters};
                    _filters['clientName'].value = e.value;
                    _filters['clientName'].matchMode = FilterMatchMode.IN;
                    setFilters(_filters);
                }}
                filter placeholder="Client name"
                className={filters.clientName.value != null ? 'p-column-filter active-filter' : 'p-column-filter'}
            />
        </div>
    );


    const ownerNameFilter = (
        <div className="multiselect-container">
            <MultiSelect
                filter placeholder="Managers"
                value={filters.ownerNames.value}
                options={ownerNames}

                onChange={(e) => {
                    let _filters = {...filters};
                    _filters['ownerNames'].value = e.value.sort((a, b) => b.localeCompare(a));
                    _filters['ownerNames'].matchMode = FilterMatchMode.CUSTOM;
                    setFilters(_filters);
                }}
                className={filters.ownerNames.value != null ? 'p-column-filter active-filter' : 'p-column-filter'}
            />
        </div>
    );


    const statusRowFilterTemplate = (
        <div className="multiselect-container">
            <MultiSelect
                value={filters.status.value}
                options={statuses}
                itemTemplate={statusItemTemplate}
                onChange={(e) => {
                    let _filters = {...filters};
                    _filters['status'].value = e.value;
                    _filters['status'].matchMode = FilterMatchMode.IN;
                    setFilters(_filters);
                }}
                placeholder="Status"
                className={filters.status.value != null ? 'p-column-filter active-filter' : 'p-column-filter'}

            />
        </div>
    );

    const getSeverityStatus = (status: string) => {
        switch (status) {
            case 'active':
                return 'success';

            case 'inactive':
                return 'warning';
        }
    };

    const header = () => {
        return (
        <div>
            <div className="text-5xl">Manage projects</div>
            <div className="flex justify-end mt-5">
                <div className="w-full flex justify-between">
                    <div className="flex items-center gap-2">
                        <span className="p-input-icon-left backdrop-opacity-90 border-b-blue-800">
                            <i className="pi pi-search"/>
                            <InputText
                                value={globalFilterValue}
                                onChange={onGlobalFilterChange}
                                placeholder="Global search"
                            />
                        </span>
                        <Button
                            className="p-button-danger"
                            type="button"
                            label="Clear all filters"
                            onClick={clearAllFilters}
                        />
                    </div>
                    <div className="flex items-center justify-end gap-2">
                        <div className="card flex justify-around ml-10">
                            <div className="flex mt-2 gap-2 ">
                                <Button
                                    label="Add Project"
                                    className="p-button-success p-button-rounded"
                                    icon="pi pi-plus"
                                    onClick={() => {
                                        setSelectedProjectToAction(null);
                                        const currentProject = {};
                                        setDialogProject(currentProject as unknown as Project);
                                        setIsEditing(false);
                                        setDialogOpen(true);
                                    }}
                                />
                            </div>
                          <div className="flex mt-2 gap-2 ">
                            <ProjectFileUploader onFileInputClick={() => {
                          }} />
                      </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        );
    };

    const showCanceled = () => {
        toast("Deletion cancelled", `No items were affected.`, "info");
    };


    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    return (
        <div className=" card max-w-full" style={{whiteSpace: 'pre-line'}}>
            <DataTable
                className="p-datatable-wrapper custom-datatable"
                value={data}
                paginator
                stripedRows
                header={header}
                rows={10}
                style={{whiteSpace: 'pre-line'}}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10, 25, 50]}
                dataKey="id"
                filters={filters}
                filterDisplay="row"
                globalFilterFields={['organizationName', 'name', 'projectCode', 'client.name', 'ownerNames', 'status']}
                sortField={sortField}
                sortOrder={sortOrder}
                emptyMessage="No projects found."
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                onRowMouseEnter={(e) => setSelectedProjectToAction(e)}
                onSort={(e) => {
                    setSortField(e.sortField);
                    setSortOrder(e.sortOrder);
                }}
            >
                <Column
                    field = "organizationName"
                    header= "Organization Name"
                    showFilterMatchModes={false}
                    bodyStyle={{overflow: 'visible'}}
                    sortable
                    fixed-size
                    filter
                    filterMatchMode="equals"
                    filterElement={organizationNameFilter}
                    className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                    onFilterClear={() => clearFilter('organizationName')}
                />
                <Column
                    field="name"
                    header="Project Name"
                    showFilterMatchModes={false}
                    bodyStyle={{overflow: 'visible'}}
                    sortable
                    fixed-size
                    filter
                    filterMatchMode="equals"
                    filterElement={projectNameFilter}
                    className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                    onFilterClear={() => clearFilter('name')}
                />
                <Column
                    field = "projectCode"
                    header= "Project code"
                    showFilterMatchModes={false}
                    bodyStyle={{overflow: 'visible'}}
                    sortable
                    fixed-size
                    filter
                    filterMatchMode="equals"
                    filterElement={projectCodeFilter}
                    className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                    onFilterClear={() => clearFilter('projectCode')}
                />
                <Column
                    field="clientName"
                    header="Client Name"
                    showFilterMatchModes={false}
                    bodyStyle={{overflow: 'visible'}}
                    sortable
                    fixed-size
                    filter
                    filterMatchMode="equals"
                    filterElement={clientRowFilterTemplate}
                    className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                    onFilterClear={() => clearFilter('clientName')}
                />

                <Column
                    field="ownerNames"
                    body={(rowData) => rowData.ownerNames.map((ownerNames) => `${ownerNames}`).join(', ')}
                    header="Manager"
                    showFilterMatchModes={false}
                    bodyStyle={{overflow: 'visible'}}
                    sortable
                    fixed-size
                    filter
                    filterMatchMode="custom"
                    filterElement={ownerNameFilter}
                    className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                    onFilterClear={() => clearFilter('ownerNames')}/>

                <Column
                    field="status"
                    header="Status"
                    className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                    bodyStyle={{overflow: 'visible'}}
                    style={{width: "10rem"}}
                    sortable
                    fixed-size
                    body={statusBodyTemplate}
                    filter
                    filterMatchMode="equals"
                    filterElement={statusRowFilterTemplate} onFilterClear={() => clearFilter('status')}/>
                <Column
                    body={
                        <>
                            <div className="flex">
                                <div className="mr-3">
                                    <ButtonInfo
                                        label="Users"
                                        onClick={async () => {
                                            const usersPath = `/projects/${selectedProjectToAction?.data.id}/users`;

                                            await router.push(
                                                {
                                                    query: {
                                                        projectName: selectedProjectToAction?.data?.name,
                                                    },
                                                    pathname: usersPath,
                                                },
                                                usersPath
                                            );
                                        }}
                                    />
                                </div>
                                <div className="mr-3">
                                    <ButtonInfo
                                        label="Tasks"
                                        onClick={async () => {
                                            const usersPath = `/projects/${selectedProjectToAction?.data.id}/tasks`;

                                            await router.push(
                                                {
                                                    query: {
                                                        projectName: selectedProjectToAction?.data?.name,
                                                    },
                                                    pathname: usersPath,
                                                },
                                                usersPath
                                            );
                                        }}
                                    />
                                </div>
                                <Button
                                    className="p-button-success p-button-rounded"
                                    icon="pi pi-pencil"
                                    onClick={() => {
                                        const currentProject = {
                                            id: selectedProjectToAction.data.id,
                                            organizationName:selectedProjectToAction.data.organizationName,
                                            projectCode:selectedProjectToAction.data.projectCode,
                                            name: selectedProjectToAction.data.name,
                                            ownerIds: selectedProjectToAction.data.owners.map(owner => owner.id),
                                            clientId: selectedProjectToAction.data.client.id,
                                            orderNumber: selectedProjectToAction.data.orderNumber,
                                            timesheet: selectedProjectToAction.data.timesheet,
                                            isInternal: selectedProjectToAction.data.isInternal,
                                            status: selectedProjectToAction.data.status
                                        };
                                        setDialogProject(currentProject as unknown as Project);
                                        setIsEditing(true);
                                        setDialogOpen(true);
                                    }}/>
                                <Button
                                    className="p-button-danger p-button-rounded"
                                    style={{marginLeft: '0.25rem'}}
                                    icon="pi pi-trash"
                                    onClick={(e) => {
                                        confirmPopup({
                                            target: e.currentTarget,
                                            message: 'Do you want to delete project: ' + selectedProjectToAction?.data?.name,
                                            icon: 'pi pi-info-circle',
                                            acceptClassName: 'p-button-danger',
                                            accept: () => deleteProject(selectedProjectToAction?.data?.id),
                                            reject: () => showCanceled(),
                                        });
                                    }}
                                />
                                <ConfirmPopup/>
                            </div>
                        </>
                    }>
                </Column>

            </DataTable>
            <EditCreateProjectDialog
                onHide={() => {
                    setDialogOpen(false)
                    fetchData()
                }}
                visible={dialogOpen}
                className="w-1/2 h-1/2"
                contentClassName="flex"
                setDialogOpen={setDialogOpen}
                updateProject={updateProject}
                dialogProject={dialogProject}
                initOwner={
                    dialogProject
                        ? (getOwnerFromProjects() as unknown as User[])
                        : null
                }
                initClient={dialogProject ? getClientFromProjects() : null}
                isEditing={isEditing}
            />
        </div>
    );
}

const deleteProjectApiCall = async (projectId: number) => {
    let resp;

    try {
        resp = await fetchFromServer(getServerUrl(`/projects-page/${projectId}`), {
            method: "DELETE",
        });
    } catch (e) {
        throw new Error("Deletion failed");
    }

    if (!(resp?.status < 300)) {
        throw new Error("Deletion failed");
    }
};
