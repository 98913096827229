import {ToastSeverityType} from "primereact/toast";
import {create} from "zustand";

export function invokeToast(
  header?: string,
  body?: string,
  severify?: ToastSeverityType
) {
  if (!header) {
    header = "Info";
  }
  if (!body) {
    body = "Alert";
  }
  if (!severify) {
    severify = "info";
  }

  useToast?.getState()?.toast(header, body, severify, 2000);
}

type ToastToggler = {
  toast: (
    summary: string,
    details: string,
    severity: ToastSeverityType,
    duration?: number
  ) => void | null;
  setToast: (newToast: any) => void;
};

export const useToast = create<ToastToggler>()((set, getState, store) => ({
  toast: () => {},
  setToast: (newToast) => {
    if (newToast) {
      set((state) => {
        return {
          ...state,
          toast: newToast,
        };
      });
    }
  },
}));
