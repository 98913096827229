import produce from "immer";

import {UserRoleDto} from "../typesDto";
import {devtools, persist} from "zustand/middleware";
import {User} from "../types";
import {create} from "zustand";

export type CurrentUserStore = {
    currentUser: User | null;
    setCurrentUser: (user: User | null) => void;
};

export const useCurrentUserStore = create<CurrentUserStore>()(
    devtools(
        (set) => ({
            currentUser: null,
            setCurrentUser: (changedUser: User | null) =>
                set(
                    produce((state: CurrentUserStore) => {
                        state.currentUser = changedUser;
                        //    invokeToast("User changed.")

                    })
                ),
        }),
    {
      name: "currentUserStore",
    }
  )
);

export type CapTimeTheme = "dark" | "light";

type ThemeStore = {
  currentTheme: CapTimeTheme;
  toggleTheme: () => void;
};

export const useThemeStore = create<ThemeStore>()(
  devtools(
    (set) => ({
      currentTheme: "dark",
      toggleTheme: () => {
        set((state) => {
          const changed: CapTimeTheme =
            state.currentTheme === "dark" ? "light" : "dark";
          return {
            ...state,
            currentTheme: changed,
          };
        });
      },
    }),
    {
      name: "themeStore",
    }
  )
);

export const useCurrentUserStore2 = create<CurrentUserStore>()(
  persist<CurrentUserStore>(
    (set, get) => ({
      currentUser: null,
      setCurrentUser: (changedUser: User | null) =>
        set(
          produce((state: CurrentUserStore) => {
            state.currentUser = changedUser;
          })
        ),
    }),
    {
      name: "cap-user-storage", // name of item in the storage (must be unique)
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
  )
);
