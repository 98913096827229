import {useQuery} from "@tanstack/react-query";
import {Dropdown} from "primereact/dropdown";
import {useEffect, useState} from "react";
import {Client} from "../../../types";
import {getServerUrl} from "../../../lib/utils";
import ButtonInfo from "../../Buttons/ButtonInfo";
import {fetchFromServer} from "../../../lib/apiCalls/Commons";

type ClientSearchDropdownProps = {
    placeholder: string,
    onClientChange: (client: Client) => void,
    initId?: number,
    initClient?: Client,
    showClearButton?: boolean,
    className?: string,
    showClear?: boolean
};
export const ClientSearchDropdown = ({
                                         placeholder,
                                         onClientChange: setResult,
                                         initId,
                                         initClient,
                                     }: ClientSearchDropdownProps) => {
    const [selectedClient, setSelectedClient] = useState<Client | null>(null);
    const [candidates, setCandidates] = useState<Client[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useQuery(
        ["clientSearchDropdown"],
        async () => {
            const resp = await fetchFromServer(
                getServerUrl(`/projects-page/clients`),
                {
                    credentials: "include",
                }
            );

            if (resp.status !== 200) {
                throw resp.status;
            }

            return resp.json();
        },
        {
            onSuccess: (data: Client[]) => {
                setCandidates(data as Client[]);
            },
            initialData: [],
        }
    );

    useEffect(() => {
        if (!!selectedClient) {
            setResult(selectedClient);
        }
    }, [selectedClient]);

    useEffect(() => {
        if (initClient) {
            setSelectedClient(initClient);
            setIsLoading(false);
            return;
        }

        if (!initId) {
            setIsLoading(false);
            return;
        }
    }, [initClient, initId]);


    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex">
            <Dropdown
                className="ml-auto w-full"
                valueTemplate={() => {
                    if (selectedClient) {
                        return (
                            <div>
                                <div>{selectedClient.name}</div>
                            </div>
                        );
                    }

                    return <span>{placeholder}</span>;
                }}
                value={selectedClient}
                options={candidates}
                filter
                showClear
                filterBy="name"
                optionLabel="name"
                onChange={(e) => {
                    const newSelectedClient = e.value as Client;
                    if (!newSelectedClient) {
                        setSelectedClient(null);
                        setResult(null);
                    } else {
                        setSelectedClient(newSelectedClient);
                    }
                }}
                placeholder={placeholder}
                itemTemplate={(client: Client) => <div>{client.name}</div>}
            ></Dropdown>
        </div>
    );
};

export default ClientSearchDropdown;
