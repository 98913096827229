import "../styles/globals.css";

import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import type {AppProps} from "next/app";
import {QueryClientProvider} from "@tanstack/react-query";
import {Guards} from "../components/Guards/Guards";
import {AppShell} from "../components/AppShell";
import {queryClient} from "../appConfig";
import {refreshTheme} from "../lib/themeSwitcher";
import React from "react";
import {AnimatePresence, motion} from "framer-motion";
import {useRouter} from "next/router";
import {initMetrics} from "../lib/metricsStore";
import ErrorBoundary from "../components/ErrorBoundary";
import Head from "next/head";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import dayjs from "dayjs";
import {projectsStore} from "./projects";
import {clientsPageStore} from "./clients";
import {usersPageStore} from "./users";

dayjs.extend(utc);
dayjs.extend(weekOfYear);

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  if (typeof window !== "undefined") {
    refreshTheme();
    initMetrics();
  }

  const clearSearchingFilters = async () => {
    //for better user experience wait for clearing filters
    await delay(250);
    projectsStore.getState().filters.status = "any";
    clientsPageStore.getState().filters.clientName = "";
    clientsPageStore.getState().filters.status = "any";
    usersPageStore.getState().userFilter.status = "any";
  }

  function delay(ms: number){
    return new Promise((resolve) => setTimeout(resolve,ms))
  }

  router.events?.on('routeChangeComplete',clearSearchingFilters);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>CapTime</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <AppShell>
          <Guards>
            <div className="relative flex flex-col flex-1">
              <AnimatePresence mode="wait">
                <motion.div
                  layout
                  initial={{ opacity: 0, y: -200, zIndex: 99 }}
                  animate={{ opacity: 1, x: 0, y: 0, zIndex: 100 }}
                  exit={{ opacity: 0, x: -100, zIndex: 99 }}
                  key={router.asPath}
                  className="flex flex-1 flex-col"
                >
                  <ErrorBoundary>
                    <div className="flex flex-col flex-1 surface-ground z-[100]">
                      <Component {...pageProps} />
                    </div>
                  </ErrorBoundary>
                </motion.div>
              </AnimatePresence>
            </div>
          </Guards>
        </AppShell>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
