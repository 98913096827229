import Link from "next/link";
import { ReactElement, useEffect, useState } from "react";
import {
  ADMIN_LINKS,
  SIDEBAR_ICONS,
  SIDEBAR_LINKS_MAP,
  USER_LINKS,
} from "../appConfig";
import { useCurrentUserStore } from "../lib/currentUserSerivce";
import { FcOvertime } from "react-icons/fc";

export default function AppSideBar({
  className,
  currentPage,
}: {
  className?: string;
  currentPage: string;
}) {
  const [activeSidebarLink, setActiveSidebarLink] = useState<string[]>([
    ...USER_LINKS,
  ]);
  const currentUser = useCurrentUserStore((state) => state.currentUser);

  useEffect(() => {
    const adminRole = currentUser.roles.find((r) => r.name === "ADMIN");
    const managerRole = currentUser.roles.find((r) => r.name === "MANAGER");

    if (adminRole || managerRole) {
      setActiveSidebarLink([...ADMIN_LINKS]);
    }
  }, [currentUser.roles]);

  return (
    <div className={`flex w-min ${className}`}>
      <div className="h-min">
        {[...activeSidebarLink].map((key) => {
          const currentHref = SIDEBAR_LINKS_MAP.get(key) ?? "/";
          return (
            <SingleSideBarLink
              currentHref={currentHref}
              key={key}
              displayName={key}
              active={currentPage === key}
            />
          );
        })}
      </div>
    </div>
  );
}

function SingleSideBarLink({
  currentHref,
  displayName,
  active,
}: {
  currentHref: string;
  displayName: string;
  active: boolean;
}): ReactElement {
  const activeClass = active ? "surface-hover" : "";

  return (
    <div className={`ct-link-hover transition-all duration-500 ${activeClass}`}>
      <Link
        href={currentHref}
        className="flex items-start w-full p-4 pb-5"
        key={currentHref}
      >
        <div className="flex justify-center items-center w-full p-4 pb-5 text-center cursor-pointer min-w-[50px] min-h-[50px]">
          {SIDEBAR_ICONS.find((o) => o.name === displayName)?.icon ?? (
            <FcOvertime
              size={"10x"}
              height={"50px"}
              width={"50px"}
              className="min-w-[50px]"
            />
          )}
          <div className="text-start p-4 w-full px-3">
            {displayName.toUpperCase()}
          </div>
        </div>
      </Link>
    </div>
  );
}
