import {getServerUrl} from "../utils";
import {User} from "../../types";
import {AuthenticationResult} from "@azure/msal-common";
import {invokeToast} from "../toastToggler";
import {msalInstance} from "../../appConfig";
import {useCurrentUserStore} from "../currentUserSerivce";
import {fetchFromServer} from "./Commons";
import {usersPageStore} from "../../pages/users";
import {UserDto} from "../../typesDto";

export const getEmptyUser = () => {
  return {
    roles: [],
    manager: null,
    billingRatePLN: null,
    id: 0,
    name: "",
    createdAt: Date.now(),
    deletedAt: null,
    updatedBy: null,
    email: "",
    surname: "",
    updatedAt: null,
  } as User;
};

export const fetchUsersApiCall = async (): Promise<UserDto[]> => {
  let response;
  const params = new URLSearchParams({
    status: usersPageStore.getState().userFilter.status,
  });
  response = await fetchFromServer(getServerUrl("/users-page?" + params));
  if (!response || !response.ok) {
    throw new Error("Error fetching users");
  }

  return await response.json() as UserDto[];
};

export const fetchUserByIdApiCall = async (userId: number): Promise<User> => {
  const resp = await fetchFromServer(getServerUrl(`/users/${userId}`), {
    credentials: "include",
  });

  if (!resp.ok) {
    throw resp.status;
  }

  return await resp.json() as User;
};

export const startLoginProcess = async () => {
  let response: AuthenticationResult | null = null;

  try {

    response = await msalInstance.loginPopup({
      scopes: ["User.Read"],
      prompt: "select_account",
      loginHint: ":)",
      redirectUri:
          process.env.NEXT_PUBLIC_REDIRECT_URL ??
          "http://localhost:3000" + "/login",
    });
  } catch (e) {
    invokeToast("Login failed", "Something went wrong", "error");
    return;
  }

  if (response) {
    const token = response.accessToken;

    try {
      const responseFromServer = await fetchFromServer(
        getServerUrl("/verify"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ accessToken: token }),
        }
      );

      if (responseFromServer.status !== 200) {
        invokeToast(
          "AUTH STATUS UPDATE",
          "ERROR: authentication on server failed.",
          "error"
        );
        return;
      }

      responseFromServer.headers.forEach((value, key) => {});

      const data = (await responseFromServer.json()) as User;

      if (!data) {
        throw "aa";
      }
      useCurrentUserStore.getState().setCurrentUser(data);
    } catch (e) {
      invokeToast("AUTHENTICATION FATAL ERROR", "Something went wrong", "error");
    }
  }
};
