import React, {useRef, useState} from "react";
import {User} from "../types";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {EditCreateUserDialog} from "../components/Popups/UserPopups/EditCreateUserDialog";
import {invokeToast} from "../lib/toastToggler";
import {UsersDatatableHeader} from "../components/Datatables/Users/ToolbarFragment";
import {useQuery} from "@tanstack/react-query";
import {fetchUsersApiCall} from "../lib/apiCalls/Users";
import {motion} from "framer-motion";
import {create} from "zustand";
import {UserDto} from "../typesDto";
import {Dropdown} from "primereact/dropdown";
import {FilterMatchMode} from "primereact/api";
import {MultiSelect} from "primereact/multiselect";
import {Tag} from "primereact/tag";
import {InputText} from "primereact/inputtext";

type UserPageFilters = {
    status: "active" | "inactive" | "any";
};

type UsersPageStoreType = {
    users: UserDto[];
    setUsers: (users: UserDto[]) => void;
    forceUpdaterRandomNumber: number;
    userFilter: UserPageFilters;
};

export const usersPageStore = create<UsersPageStoreType>()(
    (setState) => {
        const setUsers = (users: UserDto[]) => {

            setState((old) => ({
                ...old,
                users,
                forceUpdaterRandomNumber:
                    Math.random() * Math.random() * Math.random() * Math.random(),
            }));
        };

        return {
            users: [],
            setUsers,
            forceUpdaterRandomNumber:
                Math.random() * Math.random() * Math.random() * Math.random(),
            userFilter: {status: "any"},
        };
    }
);

export default function UsersPage() {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [dialogUser, setDialogUser] = useState<User | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const dataTableRef = useRef<DataTable | null>(null);
    const [rows1, setRows1] = useState(Number(localStorage.getItem("rows") ?? 5));
    const [first1, setFirst1] = useState(0);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
    const users = usersPageStore((state) => state.users);
    const forceUpdateRandomNumber = usersPageStore(
        (state) => state.forceUpdaterRandomNumber
    );
    const [filters, setFilters] = useState({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        status: {value: null, matchMode: FilterMatchMode.EQUALS},
        employeeNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        userOrganizationName: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.EQUALS },
        surname: { value: null, matchMode: FilterMatchMode.EQUALS },
        email: { value: null, matchMode: FilterMatchMode.EQUALS },
        roles: { value: null, matchMode: FilterMatchMode.IN },
        expType: { value: null, matchMode: FilterMatchMode.EQUALS },
        payroll: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const clearAllFilters = () => {
        let _filters = {
            global: {value: null, matchMode: FilterMatchMode.CONTAINS},
            status: {value: null, matchMode: FilterMatchMode.EQUALS},
            employeeNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
            userOrganizationName: { value: null, matchMode: FilterMatchMode.EQUALS },
            name: { value: null, matchMode: FilterMatchMode.EQUALS },
            surname: { value: null, matchMode: FilterMatchMode.EQUALS },
            email: { value: null, matchMode: FilterMatchMode.EQUALS },
            roles: { value: null, matchMode: FilterMatchMode.IN },
            expType: { value: null, matchMode: FilterMatchMode.EQUALS },
            payroll: { value: null, matchMode: FilterMatchMode.EQUALS },
        };
        setFilters(_filters);
        setRolesFilter([]);
        setStatusFilter('any');
        setGlobalFilterValue('');
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = {...filters};
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onCustomPage1 = (event) => {
        setRows1(event.rows);
        setFirst1(event.first);
        localStorage.setItem("rows", event.rows);
    };



    useQuery(["fetchUsers"], fetchUsersApiCall, {
        onSuccess: (data) => {
            usersPageStore.getState().setUsers(data);
        },
        onError: (err) => {
            invokeToast("fetchUsers", err.toString(), "error");
        },
    });

    const updateUser = (newUser: User) => {
        setDialogUser(newUser);
    };

    const ColumnButtonGroup = (rowData: User) => {
        return (
            <React.Fragment>
                <div className="flex">
                    <Button
                        className="p-button-success p-button-rounded"
                        icon="pi pi-pencil"
                        onClick={() => {
                            setDialogUser(rowData);
                            setIsEditing(true);
                            setDialogOpen(true);
                        }}
                    />
                </div>
            </React.Fragment>
        );
    };

    const statuses = ["active", "inactive", "any"];

    const statusBodyTemplate = (rowData) => {
        const status = rowData?.deletedAt === null ? "active" : "inactive";
        return <Tag style={{width: '5rem'}} value={status} severity={getSeverityStatus(status)}/>;
    };

    const clearFilter = (filterName) => {
        let _filters = {...filters};
        _filters[filterName].value = null;
        setFilters(_filters);
    };

    const userOrganizationNameUsers = [...new Set(users.filter(user => user.userOrganizationName !== null).map(user => user.userOrganizationName.toString()))];
    const userOrganizationNameFilter = (
      <div className="multiselect-container">
          <MultiSelect
            value={filters.userOrganizationName.value}
            options={userOrganizationNameUsers}
            display="chip"
            onChange={(e) => {
                let _filters = {...filters};
                _filters['userOrganizationName'].value = e.value;
                _filters['userOrganizationName'].matchMode = FilterMatchMode.IN;
                setFilters(_filters);
            }}
            filter
            placeholder="Organization Name"
            className={filters.userOrganizationName.value != null ? 'p-column-filter active-filter' : 'p-column-filter'}
          />
      </div>
    );

    const userEmployeeNumber = [...new Set(users.filter(user => user.employeeNumber !== null).map(user => user.employeeNumber.toString()))];
    const employeeNumberFilter = (
        <div className="multiselect-container">
            <MultiSelect
                value={filters.employeeNumber.value}
                options={userEmployeeNumber}
                display="chip"
                onChange={(e) => {
                    let _filters = {...filters};
                    _filters['employeeNumber'].value = e.value;
                    _filters['employeeNumber'].matchMode = FilterMatchMode.IN;
                    setFilters(_filters);
                }}
                filter
                placeholder="Employee Number"
                className={filters.employeeNumber.value != null ? 'p-column-filter active-filter' : 'p-column-filter'}
            />
        </div>
    );

    const userNames = [...new Set(users.map(user => user.name))];
    const nameFilter = (
        <div className="multiselect-container">
            <MultiSelect
                value={filters.name.value}
                options={userNames}
                display="chip"
                onChange={(e) => {
                    let _filters = {...filters};
                    _filters['name'].value = e.value;
                    _filters['name'].matchMode = FilterMatchMode.IN;
                    setFilters(_filters);
                }}
                filter
                placeholder="Names"
                className={filters.name.value != null ? 'p-column-filter active-filter' : 'p-column-filter'}
            />
        </div>
    );

    const userSurnames = [...new Set(users.map(user => user.surname))];
    const surnameFilter = (
        <div className="multiselect-container">
            <MultiSelect
                display="chip"
                value={filters.surname.value}
                options={userSurnames}
                onChange={(e) => {
                    let _filters = {...filters};
                    _filters['surname'].value = e.value;
                    _filters['surname'].matchMode = FilterMatchMode.IN;
                    setFilters(_filters);
                }}
                filter
                placeholder="Surnames"
                className={filters.surname.value != null ? 'p-column-filter active-filter' : 'p-column-filter'}
            />
        </div>
    );

    const userEmail = [...new Set(users.map(user => user.email))];
    const emailFilter = (
        <div className="multiselect-container">
            <MultiSelect
                display="chip"
                value={filters.email.value}
                options={userEmail}
                onChange={(e) => {
                    let _filters = {...filters};
                    _filters['email'].value = e.value;
                    _filters['email'].matchMode = FilterMatchMode.IN;
                    setFilters(_filters);
                }}
                filter
                placeholder="Emails"
                className={filters.email.value != null ? 'p-column-filter active-filter' : 'p-column-filter'}
            />
        </div>
    );


    const payrollItemTemplate = (option) => {
        return <Tag style={{width: '4rem'}} value={option} severity={getSeverityPayroll(option)}/>;
    };
    const getSeverityPayroll = (payroll: string) => {
        switch (payroll.toUpperCase()) {
            case 'YES':
                return 'success';
            case 'NO':
                return 'danger';
            case 'ANY':
                return 'info'
        }
    };

    const payrollBodyTemplate = (rowData) => {
        return <Tag style={{width: '5rem'}} value={rowData.payroll} severity={getSeverityPayroll(rowData.payroll)}/>;
    };
    const [payroll] = useState<string[]>(['Yes', 'No', 'any']);
    const [payrollFilter, setPayrollFilter] = useState('any');
    const payrollFilterTemplate = () => {
        return (
        <Dropdown
            value={payrollFilter}
            options={payroll}
            itemTemplate={payrollItemTemplate}
            onChange={(e) => setPayrollFilter(e.value)}
            placeholder="any"
            className="p-column-filter"
        />
        );
    };

    const payrollFilterFunction = (user) => {
        switch (payrollFilter.toUpperCase()) {
            case 'YES':
                return user.payroll.toUpperCase() === 'YES';
            case 'NO':
                return user.payroll.toUpperCase() === 'NO';
            case 'ANY':
                return true
        }
    };

    const userExpType = [...new Set(users.filter(user => user.expType !== null).map(user => user.expType.toString()))];
    const expTypeFilter = (
        <div className="multiselect-container">
            <MultiSelect
                value={filters.expType.value}
                options={userExpType}
                display="chip"
                onChange={(e) => {
                    let _filters = {...filters};
                    _filters['expType'].value = e.value;
                    _filters['expType'].matchMode = FilterMatchMode.IN;
                    setFilters(_filters);
                }}
                filter
                placeholder="Exp Type"
                className={filters.expType.value != null ? 'p-column-filter active-filter' : 'p-column-filter'}
            />
        </div>
    );

    const statusItemTemplate = (option) => {
        return <Tag style={{width: '4rem'}} value={option} severity={getSeverityStatus(option)}/>;
    };
    const getSeverityStatus = (status: string) => {
        switch (status) {
            case 'active':
                return 'success';
            case 'inactive':
                return 'danger';
            default:
                return 'info';
        }
    };

    const [statusFilter, setStatusFilter] = useState('any');

    const statusFilterFunction = (user) => {
        if (statusFilter === 'any') return true;
        if (statusFilter === 'active') return user.deletedAt === null;
        if (statusFilter === 'inactive') return user.deletedAt !== null;
    };

    const [rolesFilter, setRolesFilter] = useState([]);

    const rolesFilterFunction = (user) => {
        if (rolesFilter.length === 0) return true;
        return rolesFilter.some(role =>
            user.roles.some(userRole => userRole.name === role)
        );
    };

    const usersWithStatus = users.map(user => ({
        ...user,
        status: user.deletedAt === null ? 'active' : 'inactive'
    }));

    const filteredUsers = usersWithStatus.filter(statusFilterFunction).filter(rolesFilterFunction).filter(payrollFilterFunction);

    filteredUsers.sort((a, b) => a.status.localeCompare(b.status));

    const statusFilterTemplate = () => {
        return (
            <Dropdown
                value={statusFilter}
                options={statuses}
                itemTemplate={statusItemTemplate}
                onChange={(e) => setStatusFilter(e.value)}
                placeholder="Any"
                className="p-column-filter"
            />
        );
    };

    const getSeverityRole = (role: string) => {
        switch (role) {
            case 'MANAGER':
                return 'info';
            case 'ADMIN':
                return 'success';
            case 'USER':
                return 'warning';
        }
    };
    const [roles] = useState<string[]>(['MANAGER', 'ADMIN', 'USER']);

    const RolesBody = (rowData: User) => {
        return (
            <div className="flex gap-2">
                {rowData?.roles && rowData.roles.length >= 0 ? (
                    rowData.roles.map((role) => {
                        return (
                            <Tag
                                key={role.id}
                                rounded
                                value={role.name}
                                severity={getSeverityRole(role.name)}
                                className="mb-2 max-w-[70px]"
                            />
                        );
                    })
                ) : (
                    <div>-</div>
                )}
            </div>
        );
    };

    const roleFilter = (
        <div className="multiselect-container">
            <MultiSelect
                value={rolesFilter}
                options={roles}
                onChange={(e) => setRolesFilter(e.value)}
                filter
                placeholder="Roles"
                className={rolesFilter.length > 0 ? 'p-column-filter active-filter' : 'p-column-filter'}
            />
        </div>
    );

    const header = (
        <div>
            <UsersDatatableHeader
                setIsEditing={setIsEditing}
                setEditUserDialogOpen={setDialogOpen}
                setDialogUser={setDialogUser}
            />
            <div className="mt-3 flex items-center gap-2">
                <span className="p-input-icon-left backdrop-opacity-90 border-b-blue-800">
                    <i className="pi pi-search"/>
                    <InputText
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Global search"
                    />
                </span>
                <Button
                    className="p-button-danger"
                    type="button"
                    label="Clear all filters"
                    onClick={clearAllFilters}
                />
            </div>
        </div>
    )


    return (
        <motion.div
            className="flex-1"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <DataTable
                responsiveLayout="scroll"
                className="p-datatable-wrapper custom-datatable"
                value={filteredUsers}
                filters={filters}
                ref={dataTableRef}
                stripedRows
                paginator
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                rows={rows1}
                first={first1}
                onPage={onCustomPage1}
                sortMode="single"
                dataKey="id"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                filterDisplay="row"
                header={header}
            >
                <Column
                    field="name"
                    sortable
                    filter
                    filterField="name"
                    sortField="name"
                    fixed-size
                    style={{width: "15%", margin: "0"}}
                    showFilterMatchModes={false}
                    filterElement={nameFilter}
                    header={<div>NAME</div>}
                    bodyStyle={{overflow: 'visible'}}
                    className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                    body={(rowData: User) => <div>{rowData.name}</div>}
                    onFilterClear={() => clearFilter('name')}
                ></Column>

                <Column
                    field="surname"
                    sortable
                    filter
                    filterField="surname"
                    sortField="surname"
                    fixed-size
                    style={{width: "15%", padding: "0", margin: "0"}}
                    showFilterMatchModes={false}
                    filterElement={surnameFilter}
                    header={<div>SURNAME</div>}
                    bodyStyle={{overflow: 'visible'}}
                    className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                    body={(rowData: User) => <div>{rowData.surname}</div>}
                    onFilterClear={() => clearFilter('surname')}
                ></Column>
                <Column
                  field="Organization Name"
                  sortable
                  filter
                  filterField="userOrganizationName"
                  sortField="userOrganizationName"
                  fixed-size
                  style={{width: "3rem", padding: "0", margin: "0"}}
                  showFilterMatchModes={false}
                  filterElement={userOrganizationNameFilter}
                  header={<div style={{textAlign: 'center'}}>ORGANIZATION NAME</div>}
                  bodyStyle={{overflow: 'visible'}}
                  className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                  body={(rowData: User) => <div>{rowData.userOrganizationName}</div>}
                  onFilterClear={() => clearFilter('userOrganizationName')}
                ></Column>
                <Column
                    field="employeeNumber"
                    sortable
                    filter
                    filterField="employeeNumber"
                    sortField="employeeNumber"
                    fixed-size
                    style={{width: "3rem", padding: "0", margin: "0"}}
                    showFilterMatchModes={false}
                    filterElement={employeeNumberFilter}
                    header={<div style={{textAlign: 'center'}}>EMPLOYEE NUMBER</div>}
                    bodyStyle={{overflow: 'visible'}}
                    className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                    body={(rowData: User) => <div>{rowData.employeeNumber}</div>}
                    onFilterClear={() => clearFilter('employeeNumber')}
                ></Column>
                <Column
                    field="email"
                    sortable
                    filter
                    filterField="email"
                    sortField="email"
                    fixed-size
                    style={{width: "25%", padding: "0", margin: "0"}}
                    showFilterMatchModes={false}
                    filterElement={emailFilter}
                    header={<div>EMAIL</div>}
                    bodyStyle={{overflow: 'visible'}}
                    className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                    body={(rowData: User) => <div>{rowData.email}</div>}
                    onFilterClear={() => clearFilter('email')}
                ></Column>

                <Column
                    field="roles"
                    filter
                    sortable
                    style={{width: '20rem'}}
                    filterElement={roleFilter}
                    header={<div>ROLES</div>}
                    body={(rowData: User) => RolesBody(rowData)}
                    onFilterClear={() => setRolesFilter([])}
                />

                <Column
                    field="expType"
                    sortable
                    filter
                    filterField="expType"
                    sortField="expType"
                    fixed-size
                    style={{width: "3rem", padding: "0", margin: "0"}}
                    showFilterMatchModes={false}
                    filterElement={expTypeFilter}
                    header={<div style={{textAlign: 'center'}}>EXP TYPE</div>}
                    bodyStyle={{overflow: 'visible'}}
                    className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                    body={(rowData: User) => <div>{rowData.expType}</div>}
                    onFilterClear={() => clearFilter('expType')}
                ></Column>
                <Column
                    field="payroll"
                    sortable
                    filter
                    filterField="payroll"
                    filterMatchMode="equals"
                    sortField="payroll"
                    fixed-size
                    style={{width: "3rem", padding: "0", margin: "0"}}
                    showFilterMatchModes={false}
                    filterElement={payrollFilterTemplate}
                    header={<div>PAYROLL</div>}
                    bodyStyle={{overflow: 'visible'}}
                    className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                    body={payrollBodyTemplate}
                ></Column>
                <Column
                    sortable
                    filter
                    field="status"
                    filterField="status"
                    filterMatchMode="equals"
                    style={{width: "10rem"}}
                    showFilterMatchModes={false}
                    filterElement={statusFilterTemplate}
                    header={<div>STATUS</div>}
                    body={statusBodyTemplate}
                    bodyStyle={{overflow: 'visible'}}
                />

                <Column exportable={false} body={ColumnButtonGroup}></Column>
            </DataTable>
            {/* DIALOGS SECTION */}
            {/* TODO: formik, editor as note input */}
            <EditCreateUserDialog
                key={forceUpdateRandomNumber}
                onHide={() => setDialogOpen(false)}
                visible={dialogOpen}
                className="w-1/2 h-1/2"
                contentClassName="flex"
                setDialogOpen={setDialogOpen}
                updateUser={updateUser}
                dialogUser={dialogUser}
                isEditing={isEditing}
            />
        </motion.div>
    );
}
