import React, { ChangeEvent, useState, useRef } from 'react';
import { read, utils } from 'xlsx';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {queryClient} from "../../appConfig";
import {fetchFromServer} from "../../lib/apiCalls/Commons";
import {getServerUrl} from "../../lib/utils";
import {useMutation} from "@tanstack/react-query";
import {Toast} from "primereact/toast";
import {useCurrentUserStore} from "../../lib/currentUserSerivce";
import { ProgressSpinner } from 'primereact/progressspinner';



interface DataItem {
    ORGANIZATION_NAME: string;
    PROJECT_NAME: string;
    PROJECT_CODE: string;
    CLIENT_NAME: string;
    OWNER: string;
    STATUS: string;
    ORIGINAL_CLIENT_NAME: string;
    ORIGINAL_OWNER_NAMES: string;
    PROJECT_TYPE: boolean;
}

interface ProjectFileUploaderProps {
    onFileInputClick?: () => void;
    className?: string;

}
interface LoadResults {
    success: number;
    failed: number;
    errors?: string[];
}
const ProjectFileUploader: React.FC<ProjectFileUploaderProps> = ({ onFileInputClick }) => {
    const [importedProjects, setImportedProjects] = useState<DataItem[]>([]);
    const [showSubmitCancelDialog, setShowSubmitCancelDialog] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const triggerFileInputClick = () => {
        setIsLoading(false); // Set isLoading to true right before the file input click is triggered
        setTimeout(() => {
            fileInputRef.current?.click();
            onFileInputClick?.();
        }, 0); // Delay the file input click event until after the state update has been completed
    };
    const openSubmitCancelDialog = () => setShowSubmitCancelDialog(true);
    const closeSubmitCancelDialog = () => setShowSubmitCancelDialog(false);
    const clientNameToIdMap = new Map();
    const userNameToIdMap = new Map();
    const currentUser = useCurrentUserStore((state) => state.currentUser);
    const loggedInUserId = currentUser.id;
    const [loadResults, setLoadResults] = useState<LoadResults>({ success: 0, failed: 0 });    const [showLoadResults, setShowLoadResults] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [failedProjects, setFailedProjects] = useState<{name: string, reason: string}[]>([]);
    const [lastMessage, setLastMessage] = useState(null);
    const getFormattedOwnerName = (ownerName: string) => {
        const format = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]+,\s.+/; // Added "-" to the regex

        ownerName = ownerName.toLowerCase();

        // If ownerName doesn't match the format, return null
        if (!format.test(ownerName)) {
            return null;
        }

        let surname = '';
        let firstname = '';
        let rest = '';
        [surname, rest] = ownerName.split(', ');
        [firstname] = rest.split(' ');

        const formattedOwnerName = `${firstname} ${surname}`;
        return formattedOwnerName;
    }
    const fetchAndMapUsers = async () => {
        const usersResponse = await fetchFromServer(getServerUrl("/users-page"));
        const usersData = await usersResponse.json();
        if (Array.isArray(usersData)) {
            for (const user of usersData) {
                const fullName = `${user.name} ${user.surname}`;
                userNameToIdMap.set(fullName, user.id);
            }
        } else {
            console.error('Users is not an array:', usersData.users);
        }
    }

    const fetchAndMapClients = async () => {
        const clientsResponse = await fetchFromServer(getServerUrl("/clients-page"));
        const clients = await clientsResponse.json();

        for (const client of clients) {
            clientNameToIdMap.set(client.name, client.id);
        }
    }

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!currentUser.roles.some(role => role.name === 'ADMIN')) {
            setLastMessage('Only admins can import projects');
            toast.current.show({severity:'error', summary: 'Error Message', detail:'Only admins can import projects', life:100000});
            return;
        }
        if (file) {
            setIsLoading(true);
            await fetchAndMapClients();
            await fetchAndMapUsers();

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = read(data, {type: 'array'});
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];

                const seenProjectCodes = new Set();
                const jsonData: DataItem[] = (utils.sheet_to_json(worksheet, {
                    header: 1,
                    range: 16,
                }) as any[]).reduce((acc: DataItem[], row: any) => {
                    const projectCode = row[0];
                    if (seenProjectCodes.has(projectCode)) {
                        return acc;
                    }
                    // Check if the row is empty
                    if (!row.join("")) {
                        return acc;
                    }

                    seenProjectCodes.add(projectCode);
                    const clientId = clientNameToIdMap.get(row[29]); // Column AD

                    const ownerName = row[19];

                    const formattedOwnerName = getFormattedOwnerName(ownerName);

                    let formattedOwnerNameLower = formattedOwnerName.toLowerCase();
                    let ownerId = Array.from(userNameToIdMap.entries()).find(([name, _]) => name.toLowerCase() === formattedOwnerNameLower)?.[1];

                    if (!ownerId) {
                        ownerId = loggedInUserId;
                    }
                    acc.push({
                        ORGANIZATION_NAME: row[37], // Column AL
                        PROJECT_NAME: row[1], // Column B
                        PROJECT_CODE: projectCode,
                        CLIENT_NAME: clientId,
                        OWNER: ownerId,
                        STATUS: "active", // Column Y
                        ORIGINAL_CLIENT_NAME: row[29], // Column AD
                        ORIGINAL_OWNER_NAMES: row[19], // Column T
                        PROJECT_TYPE: !row[13].toLowerCase().includes('external' || 'External'),
                    });

                    return acc;
                }, []);

                setImportedProjects(jsonData);
                openSubmitCancelDialog();
                setIsLoading(false);
            };

            reader.readAsArrayBuffer(file);
        }
    };
    const toast = useRef(null);

    const saveProjectMutation = useMutation(
        ["saveProject"],
        async (project: DataItem) => {

            const newProject = {
                name: project.PROJECT_NAME,
                organizationName: project.ORGANIZATION_NAME,
                projectCode: project.PROJECT_CODE,
                clientId: project.CLIENT_NAME,
                ownerIds: [project.OWNER],
                timesheet: null,
                orderNumber: null,
                isInternal: project.PROJECT_TYPE,
            };

            const resp = await fetchFromServer(
                getServerUrl("/projects-page/create-or-edit-project"),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(newProject),
                }
            );

            if (resp.status >= 300) {
                const errorData = await resp.json(); // Parse the response body
                throw new Error(errorData.message); // Throw an error with the message from the server
            }
            return resp.json();
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(["fetchProjects"]);
            },
            onMutate: () => {},
            onError: (error: any) => {
                // toast.current.show({severity:'error', summary: 'Error Message', detail:'Failed', life:5000});
            },
        }
    );

    const addClientMutation = useMutation(
      ["addClient"],
      async (clientName: string) => {
          // Fetch and map clients
          await fetchAndMapClients();

          // Check if client already exists
          const existingClient = clientNameToIdMap.get(clientName);

          // If client already exists, return its ID
          if (existingClient) {
              return existingClient;
          }

          // If client does not exist, create a new one
          const clientWM = {
              name: clientName,
          };

          try {
              const resp = await fetchFromServer(
                getServerUrl("/clients-page"),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(clientWM),
                }
              );

              if (resp.status >= 300) {
                  const errorData = await resp.json(); // Parse the response body
                  throw new Error(errorData.message); // Throw an error with the message from the server
              }

              const responseBody = await resp.json();
              return responseBody.id; // Return the ID of the newly created client
          } catch (error) {
              if (error.message.includes('Client with name')) {
                  // If the error message indicates that the client already exists, fetch the client's ID and return it
                  const existingClientResponse = await fetchFromServer(getServerUrl(`/clients-page?name=${clientName}`));
                  const existingClientData = await existingClientResponse.json();
                  return existingClientData.id;
              } else {
                  // If the error is not about the client already existing, rethrow the error
                  throw error;
              }
          }
      },
      {
          onSuccess: async () => {
              await queryClient.invalidateQueries(["fetchClients"]);
          },
      }
    );
    const [existingProjectsCount, setExistingProjectsCount] = useState(0);

    const createNewProject = async (project: DataItem) => {
        // Fetch the client ID
        let clientId = clientNameToIdMap.get(project.ORIGINAL_CLIENT_NAME);
        if (!clientId) {
            clientId = await addClientMutation.mutateAsync(project.ORIGINAL_CLIENT_NAME);
        }
        // Fetch all users and map their full names to their IDs
        await fetchAndMapUsers();

        // Find the owner's name and surname
        const ownerNameSurname = Array.from(userNameToIdMap.entries()).find(([_, id]) => id === project.OWNER)?.[0];

        // If the owner's ID is not found, default to the logged-in user's ID
        let ownerId = userNameToIdMap.get(ownerNameSurname) || loggedInUserId;

        // Create a new project object
        const newProject: DataItem = {
            ORGANIZATION_NAME: project.ORGANIZATION_NAME,
            PROJECT_NAME: project.PROJECT_NAME,
            PROJECT_CODE: project.PROJECT_CODE,
            CLIENT_NAME: clientId.toString(),
            OWNER: ownerId,
            STATUS: null,
            ORIGINAL_CLIENT_NAME: project.ORIGINAL_CLIENT_NAME,
            ORIGINAL_OWNER_NAMES: project.OWNER,
            PROJECT_TYPE: project.PROJECT_TYPE,
        };

        // Save the project
        return saveProjectMutation.mutateAsync(newProject);
    };

    const handleProjectError = (error: any, project: DataItem) => {
        let errorMessage;
        if (error.message && error.message.includes('ProjectWriteModel.getName()" is null')) {
            errorMessage = `Missing project name for project with code: ${project.PROJECT_CODE}.`;
        } else if (error.message && error.message.includes('ClientWriteModel.getName()" is null')) {
            errorMessage = `Missing client name.`;
        } else if (error.message && error.message.includes('already exists')) {
            errorMessage = `Project ${project.PROJECT_NAME} already exists.`;
            setExistingProjectsCount(prevCount => prevCount + 1);
        } else {
            errorMessage = `Project ${project.PROJECT_NAME} failed to load due to an error.`;
        }
        return errorMessage;
    };

    const handleImportProjects = async () => {
        setIsLoading(true);
        let successCount = 0;
        let failCount = 0;
        let errorMessages = [];
        let failedProjectsArray = [];

        const projectPromises = importedProjects.map(project => createNewProject(project));

        const results = await Promise.allSettled(projectPromises);

        results.forEach((result, index) => {
            if (result.status === 'fulfilled') {
                successCount++;
            } else if (result.status === 'rejected') {
                const errorMessage = handleProjectError(result.reason, importedProjects[index]);
                // Only increment failCount and add to failedProjectsArray if the error is not 'already exists'
                if (!errorMessage.includes('already exists')) {
                    errorMessages.push(errorMessage);
                    failCount++;
                    failedProjectsArray.push({name: importedProjects[index].PROJECT_NAME, reason: errorMessage});
                }
            }
        });

        setLoadResults({ success: successCount, failed: failCount, errors: errorMessages });
        setFailedProjects(failedProjectsArray);
        setShowLoadResults(true);
        closeSubmitCancelDialog();
    };
    return (

        <div className="flex justify-center items-center w-full  text-center">
            <Toast ref={toast}/>
            <Toast ref={toast} onHide={() => {
                if (lastMessage === 'Only admins can import projects') {
                    window.location.reload();
                }
            }}/>
            <input type="file" ref={fileInputRef} style={{display: 'none'}} onChange={handleFileChange}/>
            <div className="flex items-center justify-end gap-2">
                <div className="card flex justify-around ml-1">
                    <div className="mr-3">
                        <Button
                            label=" Import Master Data"
                            className="p-button-success p-button-rounded"
                            icon="pi pi-plus"
                            onClick={triggerFileInputClick}
                        />
                    </div>
                </div>
            </div>
            {isLoading && (
                <Dialog
                    visible={isLoading}
                    modal
                    style={{width: '20vw', textAlign: 'center'}}
                    onHide={() => setIsLoading(false)}
                >
                    <h2>LOADING...</h2>
                    <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                </Dialog>
            )}
            {showSubmitCancelDialog && (
                <Dialog
                    visible={true}
                    onHide={() => {
                        closeSubmitCancelDialog();
                        window.location.reload();
                    }} header="Import Projects"
                    modal
                    style={{width: '1300px'}}
                >
                  <div className="flex  m-3">
                    <Button
                      className="p-button  p-button-outlined  p-button-raised"
                      onClick={handleImportProjects}
                    >
                      {isSubmitting ? (
                        <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                    <Button className=" p-button p-button-outlined  p-button-raised " label="Cancel"
                            onClick={() => {
                              closeSubmitCancelDialog();
                              window.location.reload();
                            }} />
                  </div>

                    <DataTable value={importedProjects} autoLayout={true}>
                        <Column field="ORGANIZATION_NAME" header="Organization Name"/>
                        <Column field="PROJECT_NAME" header="Project Name"/>
                        <Column field="PROJECT_CODE" header="Project Code"/>
                        <Column field="ORIGINAL_CLIENT_NAME" header="Client Name"/>
                        <Column field="ORIGINAL_OWNER_NAMES" header="Owner"/>
                        <Column field="STATUS" header="Status"/>
                        {/*<Column field="PROJECT_TYPE" header="Is Internal"/>*/}
                    </DataTable>
                </Dialog>
            )}
            {showLoadResults && (
                <Dialog
                    visible={true}
                    onHide={() => {
                        setShowLoadResults(false);
                        window.location.reload();
                    }}
                    header="Load Results"
                    modal
                    style={{width: '600px'}}
                >
                    <div>
                        <p>Projects loaded successfully: {loadResults.success}</p>
                        <p>Projects already exist: {existingProjectsCount}</p>
                        <p>Projects failed to load: {loadResults.failed}</p>
                        {/*{loadResults.errors && loadResults.errors.map((error, index) => (*/}
                        {/*    <p key={index}>{error}</p>*/}
                        {/*))}*/}
                    </div>
                    <DataTable value={failedProjects}>
                        <Column field="name" header="Project Name"/>
                        <Column field="reason" header="Reason"/>
                    </DataTable>
                </Dialog>
            )}
        </div>

    );
};


export default ProjectFileUploader;