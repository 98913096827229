import {Dialog, DialogProps} from "primereact/dialog";
import React, {useRef, useState} from "react";
import {Slider} from "primereact/slider";

export const CapTimeDialog = (
  props: DialogProps
) => {
  const dialogRef = useRef<Dialog | null>(null);
  const [opacity, setOpacity] = useState<number>(100);

  const mergedClassName = `${props.className ?? ""}`;

  const mergedContentClassName = `grid ${props.contentClassName ?? ""}`;

  return (
    <Dialog
      {...props}
      ref={dialogRef}
      className={mergedClassName}
      style={{
        opacity: `${opacity}%`,
      }}
      contentClassName={mergedContentClassName}
    >
      <div className="">
        {props.children}
        <div className="mt-5 w-1/2">
          <div className="mb-2">Dialog opacity:</div>
          <Slider
            className="mt-1"
            value={opacity}
            onChange={(e) => setOpacity(e.value as number)}
            min={30}
          />
        </div>
      </div>
    </Dialog>
  );
};
