import {ReactElement, useEffect, useMemo, useState} from "react";
import {useRouter} from "next/router";
import {useCurrentUserStore} from "../../lib/currentUserSerivce";
import {getServerUrl} from "../../lib/utils";
import {invokeToast} from "../../lib/toastToggler";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {User} from "../../types";
import {loginEndpoint, SIDEBAR_LINKS_MAP, USER_LINKS} from "../../appConfig";
import {fetchFromServer} from "../../lib/apiCalls/Commons";
import Center from "../Center";
import {ProjectDto} from "../../typesDto";

type AuthenticationState = "ok" | "error";
type GuardResult = "granted" | "notGranted";

const callVerifyEndpoint = async () => {
  const resp = await fetchFromServer(getServerUrl("/verify/me"));

  if (resp.status !== 200) {
    throw resp.status;
  }

  return (await resp.json()) as User;
};

const verifyPagePermissions = (user: User, page: string) => {
  if (!user) {
    return false;
  }

  const sideBarLinks = SIDEBAR_LINKS_MAP;

  const a = USER_LINKS.map((ul) => sideBarLinks.get(ul));

  if (a.includes(page)) {
    return true;
  }

  const userRoles = user.roles.map((r) => r.name);

  const isRealAdmin = userRoles.includes("ADMIN");

  if (
    !isRealAdmin &&
    page.startsWith("/projects/") &&
    (page.endsWith("users") || page.endsWith("tasks"))
  ) {
    const aa = page.split("/projects")[1].split("/")[1];
    const currentProjectId = Number(aa);

    if (
      !useCurrentUserStore
        .getState()
        .currentUser.projectsWithManagerRights.find(
          (p) => p.id === currentProjectId
        )
    ) {
      return false;
    }
  }

  const isAdmin = userRoles.includes("ADMIN") || userRoles.includes("MANAGER");

  if (!isAdmin) {
    return false;
  }

  return true;
};

type AuthCheckResponse = {
  user: User;
  projectsWithManagerRights: ProjectDto[];
};

export const Guards = ({ children }: { children: ReactElement }) => {
  const [authenticationState, setAuthenticationState] =
    useState<AuthenticationState>("error");
  const [pageLoaded, setPageLoaded] = useState(false);
  const router = useRouter();

  const guardState = useMemo<GuardResult>(() => {
    if (
      authenticationState === "ok" &&
      verifyPagePermissions(
        useCurrentUserStore.getState().currentUser,
        router.asPath
      )
    ) {
      return "granted";
    }

    return "notGranted";
  }, [
    authenticationState,
    useCurrentUserStore.getState().currentUser,
    router.asPath,
  ]);

  const queryClient = useQueryClient();
  const setCurrentUser = useCurrentUserStore((state) => state.setCurrentUser);

  useQuery(["authCheck", router.asPath], callVerifyEndpoint, {
    refetchInterval: 30000,
    retry: false,
    refetchOnWindowFocus: "always",
    onSuccess: async (response: AuthCheckResponse) => {
      await queryClient.cancelQueries(["authCheck"]);

      const user = response.user;
      user.projectsWithManagerRights = [
        ...(response?.projectsWithManagerRights ?? []),
      ];


      setCurrentUser({
        ...user,
      });

      // useToast.getState().toast("Authorization status", "Permissions granted.", "success");
      //
      //
      setAuthenticationState("ok");
    },
    onError: async (err) => {
      setAuthenticationState("error");

      if (err === 403) {
        invokeToast("Login failed", "Something went wrong", "error");
      }
      setCurrentUser(null);
      await router.push(loginEndpoint);
    },
    enabled: router.isReady && router.asPath !== loginEndpoint,
  });

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  if (!pageLoaded) {
    return <></>;
  }

  if (router.isReady && router.asPath === loginEndpoint) {
    return <>{children}</>;
  }

  if (guardState === "notGranted") {
    // TODO: (feature idea to avoid screen blinking)
    // const lastLocation = localStorage.getItem("lastLocation");
    // if ( lastLocation && lastLocation === loginEndpoint) {
    //
    //     return <LoginPage/>;
    // }

    if (
      authenticationState === "ok" &&
      !verifyPagePermissions(
        useCurrentUserStore.getState().currentUser,
        router.asPath
      )
    ) {
      return (
        <Center className="text-5xl text-red-900 p-32">
          Permission denied
        </Center>
      );
    }

    return <div>Loading ...</div>;
  }

  if (guardState === "granted") {
    return children;
  }

  return <div>ERROR (should not happen)</div>;
};
