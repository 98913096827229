import {CapTimeTheme, useThemeStore} from "./currentUserSerivce";

let currentTheme = "dark";

const getThemeUrl = (themeName: string) => {
  return `/themes/${themeName}/theme.css`;
};

const getThemeName = (theme: CapTimeTheme) => {
  switch (theme) {
    case "dark":
      return "vela-blue";
    case "light":
      return "saga-blue";
    default:
      return "vela-blue";
  }
};

export const refreshTheme = () => {
  const theme = (localStorage.getItem("theme") as CapTimeTheme) || "dark";
  if (currentTheme === theme) return;
  setTheme(theme);
};

export const toggleTheme = () => {
  if (localStorage.getItem("theme") === null) {
    // default theme is dark
    localStorage.setItem("theme", "light");
  } else {
    localStorage.getItem("theme") === "dark"
      ? localStorage.setItem("theme", "light")
      : localStorage.setItem("theme", "dark");
  }
  const currentTheme = localStorage.getItem("theme") as CapTimeTheme;
  setTheme(currentTheme);
};

export const setTheme = (theme: CapTimeTheme) => {
  const themeName = getThemeName(theme);
  const themeUrl = getThemeUrl(themeName);

  document.querySelector("#theme-link").setAttribute("href", themeUrl);
  localStorage.setItem("theme", theme);
  useThemeStore.setState((old) => ({ ...old, currentTheme: theme }));
  currentTheme = theme;
};
