import {DialogProps} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {
    ALL_EXPTYPE_OPTIONS,
    ALL_PAYROLL_OPTIONS,
    ALL_STATUS_OPTIONS,
    ExpTypeOption,
    PayrollOption,
    Role,
    User,
} from "../../../types";
import React, {useEffect, useRef, useState} from "react";
import ButtonSuccess from "../../Buttons/ButtonSuccess";
import ButtonDanger from "../../Buttons/ButtonDanger";
import {getServerUrl} from "../../../lib/utils";
import {invokeToast, useToast} from "../../../lib/toastToggler";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CapTimeDialog} from "../../CapTimeModal";
import {fetchFromServer} from "../../../lib/apiCalls/Commons";
import {useCurrentUserStore} from "../../../lib/currentUserSerivce";
import {Dropdown} from "primereact/dropdown";
import {ProjectDto, UserDto} from "../../../typesDto";
import {MultiSelect} from "primereact/multiselect";
import {getUnixTime} from "date-fns";

type Props = DialogProps & {
  setDialogOpen: (dialogState: boolean) => void;
  dialogUser: User | null;
  isEditing: boolean;
  updateUser: (user: User) => void;
};

const getEmptyUser = () => ({
    id: 0,
    surname: "",
    userOrganizationName: "",
    employeeNumber: null,
    createdAt: null,
    updatedAt: null,
    updatedBy: null,
    deletedAt: null,
    name: "",
    managerId: null,
    billingRatePLN: 0,
    expType: "",
    email: "",
    roles: [],
    status: "",
    projectIds: [],
});

export function EditCreateUserDialog(props: Props) {
  const { setDialogOpen, isEditing, dialogUser } = props;

  const [localDialogUser, setLocalDialogUser] = useState<User>(getEmptyUser());
  const queryClient = useQueryClient();
  const toast = useToast((state) => state.toast);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const currentUser = useCurrentUserStore((state) => state.currentUser);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

    useEffect(() => {
        if (props.visible) {
            // Reset attemptedSubmit state when the dialog is opened
            setAttemptedSubmit(false);
        }
    }, [props.visible]);

    const saveUserMutation = useMutation(
    ["saveUser"],
    async (dialogUser: User) => {
      const payload = {
        userToEditId: dialogUser.id,
        name: dialogUser.name,
        surname: dialogUser.surname,
        employeeNumber: dialogUser.employeeNumber,
        userOrganizationName: dialogUser.userOrganizationName,
        email: dialogUser.email,
        expType: dialogUser.expType,
        payroll: dialogUser.payroll,
        rolesIds: dialogUser.roles.map((r) => r.id),
        projectIds: dialogUser.projectsIds,
        status: dialogUser.status,
        deletedAt: dialogUser.deletedAt,
      } as AddOrEditUserRequest;

      if (!currentUser.roles.find((r) => r.name === "ADMIN")) {
        payload.rolesIds = [];
      }

      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      const url = "/users-page/" + (isEditing ? "edit-user" : "add-user");

      const resp = await fetchFromServer(getServerUrl(url), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (resp.status >= 300) {
        throw "Received " + resp.status + " status code.";
      }

    },
    {
      onSuccess: async () => {
        setDialogOpen(false);
        setLocalDialogUser(getEmptyUser());
        await queryClient.invalidateQueries(["authCheck"]);
        await queryClient.invalidateQueries(["fetchUsers"]);
        toast(`${isEditing ? "Update" : "Create"} succeed.`, "", "success");
      },
      onMutate: () => {
        toast(
          `${isEditing ? "Update" : "Create"} process started...`,
          "",
          "info"
        );
      },
      onError: () => {
        toast(
          `${isEditing ? "Update" : "Create"} process failed.`,
          "",
          "error"
        );
      },
      onSettled: () => {
        setDialogOpen(false);
      },
    }
  );

  useEffect(() => {
    if (dialogUser) {
      setLocalDialogUser(dialogUser);
    }
  }, [dialogUser]);

  const onButtonSuccess = async () => {
      setAttemptedSubmit(true);
      if (!localDialogUser || localDialogUser.email.length === 0) {
      invokeToast(
        "Form validation error",
        "Valid email must be provided.",
        "error"
      );
      return;
    }

    if (localDialogUser.name.trim().length === 0) {
      invokeToast(
        "Form validation error",
        "User name cannot be empty",
        "error"
      );
      return;
    }

    if (localDialogUser.surname.trim().length === 0) {
      invokeToast(
        "Form validation error",
        "User surname cannot be empty",
        "error"
      );
      return;
    }

    if (
      !localDialogUser.email.includes("@") ||
      !localDialogUser.email.includes(".")
    ) {
      invokeToast("Form validation error", "Invalid email format.", "error");
      return;
    }

    if (!localDialogUser.email.endsWith("@capgemini.com") && false) {
      invokeToast(
        "Form validation error",
        "Email must be from capgemini.com domain",
        "error"
      );
      return;
    }

    saveUserMutation.mutate(localDialogUser);
  };

  const DialogFooter = () => {
    return (
      <div className="flex justify-end mr-12">
        <ButtonSuccess
          label={isEditing ? "Update" : "Create"}
          onClick={onButtonSuccess}
        />
        <div className="ml-2">
          <ButtonDanger
            label="Cancel"
            onClick={() => {
              toast("", "Cancelled", "error");
              setDialogOpen(false);
            }}
          />
        </div>
      </div>
    );
  };

  const DialogHeader = () => {
    return (
      <h1 className="text-2xl mb-5">
        {props.isEditing ? "Edit user" : "New user"}
      </h1>
    );
  };

  return (
    <div className="flex" ref={modalRef}>
      <CapTimeDialog
        {...props}
        header={DialogHeader}
        className="h-[650px] w-1/2"
        footer={DialogFooter}
      >
        <div className="flex flex-1 flex-col">
            <form className="flex flex-1 flex-col">
                <button
                    type="submit"
                    className="hidden"
                    onClick={(e) => {
                        e.preventDefault();
                        onButtonSuccess();
                    }}
                />
                <h1 className="mb-2">Name *</h1>
                <InputText
                    className=""
                    value={localDialogUser?.name ?? ""}
                    onChange={(e) =>
                        setLocalDialogUser((old) => ({
                            ...old,
                            name: e.target.value,
                        }))
                    }
                />
                {attemptedSubmit && localDialogUser.name.trim().length === 0 && (
                    <div className="error-message">User name is required</div>
                )}
                <h1 className="mb-2">Surname *</h1>
                <InputText
                    className=""
                    value={localDialogUser?.surname ?? ""}
                    onChange={(e) => {
                        setLocalDialogUser((old) => ({
                            ...old,
                            surname: e.target.value,
                        }));
                    }}
                />
                {attemptedSubmit && localDialogUser.surname.trim().length === 0 && (
                    <div className="error-message">User surname is required</div>
                )}
              <h1 className="mb-2">Organization Name</h1>
              <InputText
                className=""
                value={localDialogUser?.userOrganizationName ?? ""}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setLocalDialogUser((old) => ({
                      ...old,
                      userOrganizationName: e.target.value
                    }));
                  } else {
                    setLocalDialogUser((old) => ({
                      ...old,
                      userOrganizationName: null
                    }));
                  }
                }}
              />
              <h1 className="mb-2">Employee Number</h1>
                <InputText
                    className=""
                    value={localDialogUser?.employeeNumber ?? "" }
                    onChange={(e) => {
                        if (e.target.value.length > 0) {
                            setLocalDialogUser((old) => ({
                                ...old,
                                employeeNumber: e.target.value,
                            }));
                        } else {
                            setLocalDialogUser((old) => ({
                                ...old,
                                employeeNumber: null,
                            }));
                        }
                    }}
                />
                <h1 className="mb-2">Email *</h1>
                <div className="">
                    <div className="p-inputgroup">
                        <InputText
                            className=""
                            value={localDialogUser?.email ?? ""}
                            onChange={(e) => {
                                setLocalDialogUser((old) => ({
                                    ...old,
                                    email: e.target.value,
                                }));
                            }}
                        />
                    </div>
                </div>
                {attemptedSubmit && (
                    !localDialogUser.email.includes("@") ||
                    !localDialogUser.email.includes(".") ||
                    !localDialogUser.email.endsWith("@capgemini.com")
                ) && (
                    <div className="error-message">Email is empty or wrong format (eg. xxx@capgemini.com)</div>
                )}
                <h1 className="mb-2">Projects</h1>

                <ProjectSearch
                    onChange={(projects: ProjectDto[]) => {
                        setLocalDialogUser((old) => ({
                            ...old,
                            projectsIds: projects?.map((p) => p.id) ?? []
                        }));
                    }}
                    dialogUser={localDialogUser}
                />
              <h1 className="mb-2">ExpType</h1>
              <Dropdown
                  className=""
                  placeholder="Exp Type"
                  value={localDialogUser?.expType}
                  options={[...ALL_EXPTYPE_OPTIONS.values()]}
                  onChange={(e) =>
                      setLocalDialogUser((old) => ({
                        ...old,
                        expType: e.value as ExpTypeOption,
                      }))
                  }
                  showClear={true}
              />
            <h1 className="mb-2">Payroll</h1>
            <Dropdown
              className=""
              options={[...ALL_PAYROLL_OPTIONS.values()]}
              placeholder="Payroll option"
              value={localDialogUser?.payroll}
              onChange={(e) =>
                setLocalDialogUser((old) => ({
                  ...old,
                  payroll: e.value as PayrollOption,
                }))
              }
            />
            {currentUser.roles.find((r) => r.name === "ADMIN") && (
              <>
                <h1 className="mb-2">Roles</h1>
                <RoleMultiSelect
                  setSelectedRoles={(roles) => {
                    setLocalDialogUser((old) => ({
                      ...old,
                      roles: [...roles],
                    }));
                  }}
                  initialRoles={
                    localDialogUser?.roles?.length &&
                    localDialogUser?.roles?.length > 0
                      ? [...localDialogUser.roles]
                      : null
                  }
                />
              </>
            )}
            <h1 className="mb-2">Status</h1>
            <Dropdown
              className=""
              options={[...ALL_STATUS_OPTIONS.values()]}
              placeholder="User status"
              value={
                localDialogUser?.deletedAt === null ? "active" : "inactive"
              }
              onChange={() =>
                setLocalDialogUser((old) => {
                  return {
                    ...old,
                    deletedAt: old.deletedAt
                      ? null
                      : getUnixTime(new Date()) * 1000,
                  };
                })
              }
            />
          </form>
        </div>
      </CapTimeDialog>
    </div>
  );
}

type ProjectSearchRquest = {
    contains: string;
    onBehalfOf: number;
};

type ProjectSearchProps = {
    onChange: (projects: ProjectDto[]) => void;
    dialogUser: User;
};

function ProjectSearch({onChange, dialogUser}: ProjectSearchProps) {
    const [projects, setProjects] = useState<ProjectDto[]>([]);
  const queryClient = useQueryClient();
  const [val, setVal] = useState<ProjectDto[]>([]);
  const filter = useRef<ProjectSearchRquest>({
    onBehalfOf: null,
    contains: "",
  });

  useQuery(
    ["fetchProjectSearch"],
    async () => {
      return fetchProjectSearchUsersPage();
    },
    {
      onSuccess: (data) => {
        setProjects(data);
        if(dialogUser && dialogUser?.projectsIds) {
            setVal(data.filter((p) => dialogUser.projectsIds.includes(p.id)));
        }
      },
      onError: (err) => {
        invokeToast("err", err.toString(), "error");
      },
    }
  );

  return (
    <MultiSelect
      value={val}
      className=""
      itemTemplate={(val) => <div>{val.name}</div>}
      selectedItemTemplate={(u: UserDto) =>
        u ? <span>{u.name}</span> : "Select project(s)"
      }
      panelFooterTemplate={(props, hide) => (

          <div className="flex justify-between items-center">
            <div className={"p-4"}>{val?.length ?? 0} items selected.</div>
            <div className="mr-3">
              <ButtonSuccess
                  icon="pi pi-plus"
                  label="Confirm"
                  onClick={hide}
              />
            </div>
          </div>
      )}
      filter
      onFilter={(e) => e.originalEvent.preventDefault()}
      filterTemplate={() => {
        return (
          <div className="flex gap-2 mx-2 w-full">
            <InputText
              placeholder="Search..."
              className="w-full"
              onChange={async (e) => {
                filter.current.contains = e.target.value;
                await queryClient.invalidateQueries(["fetchProjectSearch"]);
              }}
            />
          </div>
        );
      }}
      showClear={false}
      maxSelectedLabels={0}
      optionLabel="name"
      options={projects}
      selectAll={true}
      placeholder="Select projects"
      onHide={async () => {
        onChange(val);
        await queryClient.invalidateQueries(["fetchProjectSearch"]);
      }}
      onChange={(e) => {
        setVal(e.value);
      }}
    />
  );
}

export const fetchProjectSearchUsersPage = async (
) => {
  const resp = await fetchFromServer(
    getServerUrl("/users-page/available-projects"),
    {
      method: "GET"
    }
  );
    return await resp.json() as ProjectDto[];
};

type RoleMultiSelectProps = {
  setSelectedRoles: (roles: Role[]) => void;
  initialRoles?: Role[];
};

const RoleMultiSelect = ({
  setSelectedRoles: setResult,
  initialRoles,
}: RoleMultiSelectProps) => {
  const [isLoading, setLoginLoading] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<Role[] | null>(null);
  const [selectAll, setSelectAll] = useState(false);

  const { error } = useQuery(
    ["fetchRoles"],
    async () => {
      const resp = await fetchFromServer(
        getServerUrl("/users-page/get-all-roles")
      );

      if (resp.status !== 200) {
        invokeToast("Fetching error", "Could not fetch roles.", "error");
        throw resp.status;
      }

      return (await resp.json()) as Role[];
    },
    {
      onSuccess: (data: Role[]) => {
        setLoginLoading(false);
        setRoles(data);

        if (!initialRoles) return;
        if (initialRoles.length === roles.length) {
          setSelectAll(true);
        } else {
          setSelectedRoles(() => [...initialRoles]);
          setResult([...initialRoles]);
        }
      },
      onError: () => {
        invokeToast("Fetching error", "Could not fetch roles.", "error");

        setLoginLoading(false);
      },
      enabled: roles.length === 0,
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <MultiSelect
      value={selectedRoles}
      onSelectAll={(e) => {
        setSelectAll(!e.checked);
        setSelectedRoles(e.checked ? [] : [...roles]);
      }}
      optionLabel="name"
      dataKey="name"
      options={roles}
      selectAll={selectAll}
      display="chip"
      onChange={(e) => {
        let newRoles = e.value as Role[];
        newRoles = newRoles.filter((e) => e);
        if (!e.value) {
          return;
        }
        setSelectedRoles(() => [...newRoles]);
        setResult([...newRoles]);
        setSelectAll(roles.length === selectedRoles?.length);
      }}
      placeholder="Select roles"
    />
  );
};

type AddOrEditUserRequest = {
  userToEditId: number;
  name: String;
  surname: String;
  email: String;
  projectIds: number[];
  expType: ExpTypeOption;
  payroll: PayrollOption;
  rolesIds: number[];
  deletedAt: number | null;
};
