import { useThemeStore } from "../lib/currentUserSerivce";
import { toggleTheme } from "../lib/themeSwitcher";

export type SwitchThemeButtonProps = {
  className?: string;
};
export const SwitchThemeButton = ({ className }: SwitchThemeButtonProps) => {
  const currentTheme = useThemeStore((state) => state.currentTheme);
  const darkClasses = "pi pi-moon";
  const lightClasses = "pi pi-sun";

  const isDarkMode = currentTheme === "dark";

  const classes = `${isDarkMode ? lightClasses : darkClasses} ${className}`;

  return (
    <>
      <i
        className={classes}
        style={{ fontSize: "2em" }}
        onClick={() => {
          toggleTheme();
        }}
      />
    </>
  );
};
