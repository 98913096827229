import React from "react";
import ButtonSuccess from "../../Buttons/ButtonSuccess";
import {getEmptyUser} from "../../../lib/apiCalls/Users";

type ToolbarContentProps = {
  setIsEditing: (boolean) => void;
  setDialogUser: (User) => void;
  setEditUserDialogOpen: (boolean) => void;
};
export const UsersDatatableHeader = ({
  setIsEditing,
  setDialogUser,
  setEditUserDialogOpen,
}: ToolbarContentProps) => {
  return (
    <React.Fragment>
      <div className="flex flex-col w-full">
        <div className="flex justify-between my-3 mb-5">
          <div className="text-5xl">Manage users</div>
          <div className="flex justify-center items-center">
            <div className="mr-3">
              <ButtonSuccess
                icon="pi pi-plus"
                label="NEW"
                onClick={() => {
                  setIsEditing(false);
                  setDialogUser(getEmptyUser());
                  setEditUserDialogOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
