import React, {useRef, useState} from "react";
import {Client} from "../types";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {invokeToast, useToast} from "../lib/toastToggler";
import {ClientsDatatableHeader} from "../components/Datatables/Clients/ToolbarFragment";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {EditClientDialog} from "../components/Guards/ClientPopups/EditClientDialog";
import {motion} from "framer-motion";
import {FilterMatchMode} from "primereact/api";
import {Dropdown} from "primereact/dropdown";
import {MultiSelect} from "primereact/multiselect";

import {create} from "zustand";
import {fetchFromServer} from "../lib/apiCalls/Commons";
import {getServerUrl} from "../lib/utils";
import {Tag} from "primereact/tag";
import {InputText} from "primereact/inputtext";

export default function ClientsPage() {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [dialogClient, setDialogClient] = useState<Client | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const dataTableRef = useRef<DataTable | null>(null);
    const [noteDesc, setNoteDesc] = useState('');
    const {toast} = useToast();
    const [rows1, setRows1] = useState(Number(localStorage.getItem("rows") ?? 5));
    const [first1, setFirst1] = useState(0);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
    const [filters, setFilters] = useState({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        status: {value: null, matchMode: FilterMatchMode.EQUALS},
        id: {value: null, matchMode: FilterMatchMode.EQUALS},
        name: {value: null, matchMode: FilterMatchMode.EQUALS},
        note: {value: null, matchMode: FilterMatchMode.EQUALS},
    });
    const clearAllFilters = () => {
        let _filters = {
            global: {value: null, matchMode: FilterMatchMode.CONTAINS},
            status: {value: null, matchMode: FilterMatchMode.EQUALS},
            id: {value: null, matchMode: FilterMatchMode.EQUALS},
            name: {value: null, matchMode: FilterMatchMode.EQUALS},
            note: {value: null, matchMode: FilterMatchMode.EQUALS},
        };
        setFilters(_filters);
        setStatusFilter('any');
        setGlobalFilterValue('');
        setNoteDesc('')
    };

    const clientNameFilter = clientsPageStore(
        (state) => state.filters.clientName
    );

    const onCustomPage1 = (event) => {
        setRows1(event.rows);
        setFirst1(event.first);
        localStorage.setItem("rows", event.rows);
    };

    const queryClient = useQueryClient();
    const {data: clients} = useQuery(
        ["fetchClients", clientNameFilter],
        fetchClientsApiCall,
        {
            onError: (err) => {
                invokeToast("fetchClients", err.toString(), "error");
            },
        }
    );
    useMutation(["deleteClient"], deleteClientApiCall, {
        onMutate: () => {
            toast("Status update", "Deletion process started...", "info");
        },
        onSuccess: async () => {
            toast("Deletion succeed.", "1 item affected.", "success");
            await queryClient.invalidateQueries(["fetchClients"]);
        },
        onError: () => {
            toast("Deletion failed.", "No items affected.", "error");
        },
    });
    const updateClient = (newClient: Client) => {
        setDialogClient(newClient);
    };
    const ActionBodyTemplate = (rowData: Client) => {
        return (
            <React.Fragment>
                <div className="flex">
                    <Button
                        className="p-button-success p-button-rounded"
                        icon="pi pi-pencil"
                        onClick={() => {
                            setDialogClient(rowData);
                            setIsEditing(true);
                            setDialogOpen(true);
                        }}
                    />
                </div>
            </React.Fragment>
        );
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = {...filters};
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const clearFilter = (filterName) => {
        let _filters = {...filters};
        _filters[filterName].value = null;
        setFilters(_filters);
    };

    const clientName = clients ? clients.map(client => ({label: client.name.toString(), value: client.name})) : [];
    const nameFilter = (
        <div className="multiselect-container">
            <MultiSelect
                display="chip"
                value={filters.name.value}
                options={clientName}
                onChange={(e) => {
                    let _filters = {...filters};
                    _filters['name'].value = e.value;
                    _filters['name'].matchMode = FilterMatchMode.IN;
                    setFilters(_filters);
                }}
                filter
                placeholder="Names"
                className={filters.name.value != null ? 'p-column-filter active-filter' : 'p-column-filter'}
            />
        </div>
    );


    const noteDescRowFilterTemplate = (
        <InputText
            value={noteDesc}
            onChange={(e) => {
                setNoteDesc(e.target.value);
                let _filters = {...filters};
                _filters['note'].value = e.target.value;
                _filters['note'].matchMode = FilterMatchMode.CONTAINS;
                setFilters(_filters);
            }}
            placeholder="Search by note"
            className="p-column-filter"
        />
    );

    const statuses = ["active", "inactive", "any"];
    const [statusFilter, setStatusFilter] = useState('any');

    const statusFilterFunction = (user) => {
        if (statusFilter === 'any') return true;
        if (statusFilter === 'active') return user.deletedAt === null;
        if (statusFilter === 'inactive') return user.deletedAt !== null;
    };
    const statusFilterTemplate = () => {
        return (
            <Dropdown
                value={statusFilter}
                options={statuses}
                itemTemplate={statusItemTemplate}
                onChange={(e) => setStatusFilter(e.value)}
                placeholder="Any"
                className="p-column-filter"
            />
        );
    };

    const mappedClients = clients?.map(client => ({
        ...client,
        sortableStatus: client.deletedAt === null ? 1 : 0
    }));

    const filteredAndSortedClients = mappedClients
        ?.filter(statusFilterFunction)
        .sort((a, b) => b.sortableStatus - a.sortableStatus); // This will put active clients on top

    const statusBodyTemplate = (rowData) => {
        const status = rowData?.deletedAt === null ? "active" : "inactive";
        return <Tag style={{width: '6rem'}} value={status} severity={getSeverityStatus(status)}/>;
    };

    const statusItemTemplate = (option) => {
        return <Tag style={{width: '6rem'}} value={option} severity={getSeverityStatus(option)}/>;
    };
    const getSeverityStatus = (status: string) => {
        switch (status) {
            case 'active':
                return 'success';
            case 'inactive':
                return 'danger';
            default:
                return 'info';
        }
    };

    const header = () => (
        <div>
            <ClientsDatatableHeader
                setIsEditing={setIsEditing}
                setEditClientDialogOpen={setDialogOpen}
                setDialogClient={setDialogClient}
            />
            <div className="mt-4 flex items-center gap-2">
                <span className="p-input-icon-left backdrop-opacity-90 border-b-blue-800">
                    <i className="pi pi-search"/>
                    <InputText
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Global search"
                    />
                </span>
                <Button
                    className="p-button-danger"
                    type="button"
                    label="Clear all filters"
                    onClick={clearAllFilters}
                />
            </div>
        </div>
    )

    return (
        <motion.div
            className="flex-1"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <DataTable
                className="p-datatable-wrapper custom-datatable"
                value={filteredAndSortedClients}
                filters={filters}
                ref={dataTableRef}
                stripedRows
                responsiveLayout="scroll"
                paginator
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                rows={rows1}
                first={first1}
                onPage={onCustomPage1}
                sortMode="single"
                filterDisplay="row"
                dataKey="id"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                header={header}
            >

                <Column
                    field="name"
                    sortable
                    filter
                    filterField="name"
                    sortField="name"
                    fixed-size
                    style={{width: "40%", margin: "0"}}
                    showFilterMatchModes={false}
                    filterElement={nameFilter}
                    header={<div>NAME</div>}
                    bodyStyle={{overflow: 'visible'}}
                    className="hide-filter-icon hide-filter-menu-button hide hide-clear-button "
                    body={(rowData: Client) => <div>{rowData.name}</div>}
                    onFilterClear={() => clearFilter('name')}
                />

                <Column
                    field="note"
                    filter
                    sortable
                    sortField="note"
                    style={{ width: "40%", textAlign: "center" }}
                    showFilterMatchModes={false}
                    filterElement={noteDescRowFilterTemplate}
                    header={<div style={{ textAlign: "center" }}>NOTE</div>}
                    body={(rowData: Client) => <div>{rowData.note}</div>}
                    onFilterClear={() => clearFilter("note")}
                />

                <Column
                    sortable
                    filter
                    field="status"
                    sortField="sortableStatus" // Use sortableStatus as the sortField
                    filterMatchMode="equals"
                    style={{width: "10%"}}
                    showFilterMatchModes={false}
                    filterElement={statusFilterTemplate}
                    header={<div>STATUS</div>}
                    body={statusBodyTemplate}
                    bodyStyle={{overflow: 'visible'}}
                    onFilterClear={() => clearFilter('status')}
                />

                <Column exportable={false} body={ActionBodyTemplate}></Column>
            </DataTable>
            {/* DIALOGS SECTION */}
            {/* TODO: formik, editor as note input */}

            <EditClientDialog
                onHide={() => setDialogOpen(false)}
                visible={dialogOpen}
                className="w-1/2 h-1/2"
                contentClassName="flex"
                setDialogOpen={setDialogOpen}
                updateClient={updateClient}
                dialogClient={dialogClient}
                isEditing={isEditing}
            />
        </motion.div>
    );
}

type ClientsPageFilters = {
    clientName: string;
    status: "any" | "active" | "inactive";
};

type ClientsPageStoreType = {
    filters: ClientsPageFilters;
};

export const clientsPageStore = create<ClientsPageStoreType>()(
    () => {
        return {
            filters: {clientName: "", status: "any"},
        };
    }
);

const deleteClientApiCall = async (projectId: number) => {
    let resp;

    try {
        resp = await fetchFromServer(getServerUrl(`/clients-page/${projectId}`), {
            method: "DELETE",
        });
    } catch (e) {
        throw new Error("Deletion failed");
    }

    if (!(resp?.status < 300)) {
        throw new Error("Deletion failed");
    }
};

export const fetchClientsApiCall = async (): Promise<Client[]> => {
    let response;

    const params = new URLSearchParams({
        clientName: clientsPageStore.getState().filters.clientName,
        status: clientsPageStore.getState().filters.status,
    });

    try {
        response = await fetchFromServer(getServerUrl("/clients-page?" + params), {
            credentials: "include",
        });
    } catch (exp) {
        throw new Error("Error from rest api.");
    }

    if (!response || !response.ok) {
        invokeToast(
            "error",
            "Received invalid response code from rest api.",
            "error"
        );
        throw new Error("Received invalid response code from rest api.");
    }

    return await response.json() as Client[];
};
