import {create} from "zustand";
import {fetchFromServer} from "./apiCalls/Commons";
import {getServerUrl} from "./utils";

type statsStoreType = {
  stats: string[];
  setStats: (stats: string[]) => void;
};

export const statsStore = create<statsStoreType>()(
    (setState, getState, store) => {
      return {
        stats: [],
        setStats: (stats: string[]) => {
          setState({stats: stats});
        },
      };
    }
);
if (typeof window !== "undefined") {
  console.trace("!");
  console.trace = () => {

  };
  console.error = () => {};
  console.assert = () => {};
}

let initialized = false;
export const initMetrics = () => {
  if (initialized == true) {
    return;
  }
  initialized = true;

  window.onerror = function (event, source, lineno, colno, error) {
    const errInfo =
      event + " " + source + " " + lineno + " " + colno + " " + error;
    // errors.push(errInfo);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    fetchFromServer(getServerUrl("/authorizee"), {
      method: "POST",
      headers,
      body: JSON.stringify({
        errors: [errInfo],
      }),
    });
    return true;
  };
};
