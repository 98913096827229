import {useQuery} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import {User} from "../../../types";
import {getServerUrl} from "../../../lib/utils";
import ButtonInfo from "../../Buttons/ButtonInfo";
import {fetchFromServer} from "../../../lib/apiCalls/Commons";
import {MultiSelect} from "primereact/multiselect";

type ManagersSearchDropdownProps = {
    userRole?: string,
    placeholder: string,
    onUserChange: (user: User[]) => void,
    initUsers?: User[],
    showClearButton?: boolean,
    className?: string,
    display?: string,
    showClear?: boolean,
    loggedInUser?: User,
    isEditing: boolean
};
export const ManagersSearchDropdown = ({
                                           userRole,
                                           placeholder,
                                           onUserChange: setResult,
                                           initUsers,
                                           className,
                                           loggedInUser,
                                           isEditing
                                       }: ManagersSearchDropdownProps) => {
    const [selectedUsers, setSelectedUsers] = useState<User[]>(initUsers ?? []);
    const [candidates, setCandidates] = useState<User[]>([]);
    const checkAdmin = loggedInUser.roles.find((r) => r.name === "ADMIN");
    useQuery(
        ["userSearchDropdown"],
        async () => {
            let resp = await fetchFromServer(
                getServerUrl(
                    `/projects-page/all-users-with-role?roleName=${encodeURIComponent(
                        userRole.toUpperCase()
                    )}`
                )
            );

            if (resp.status !== 200) {
                throw resp.status;
            }

            return resp.json();
        },
        {
            onSuccess: (data: User[]) => {
                setCandidates(data as User[]);
                if(!checkAdmin && !isEditing) {
                    const loggedInManager = data.find(user => user.id === loggedInUser?.id) as User;
                    setSelectedUsers(loggedInManager ? [loggedInManager] : []);
                }
            },
            initialData: [],
        }
    );

    useEffect(() => {
        if (selectedUsers) {
            setResult(selectedUsers);
        }
    }, [selectedUsers]);

    return (
        <div className="flex max-w-[800px] h-min">
            <MultiSelect
                value={selectedUsers}
                options={candidates}
                filter
                showClear={true}
                filterBy="name,surname"
                optionLabel="email"
                optionDisabled={(user: User) => checkAdmin ? false : user.id === loggedInUser?.id}
                onChange={(e) => {
                    let newSelectedUsers = e.value as User[];
                    if (checkAdmin && (!newSelectedUsers || newSelectedUsers.length === 0)) {
                        setSelectedUsers([]);
                    } else {
                        if (!checkAdmin) {
                            const loggedInManager = candidates.find(user => user.id === loggedInUser?.id) as User;
                            if ((!newSelectedUsers || newSelectedUsers.length === 0)) {
                                newSelectedUsers = loggedInManager ? [loggedInManager] : [];
                            }
                            if (!newSelectedUsers.some(user => user.id === loggedInUser?.id)) {
                                newSelectedUsers = loggedInManager ? [...newSelectedUsers, loggedInManager] : [...newSelectedUsers];
                            }
                        }
                        setSelectedUsers(newSelectedUsers);
                    }
                }}
                placeholder={placeholder}
                itemTemplate={(user: User) => <div>{user.email}</div>}
                className={className}
            />
        </div>
    );
};

export default ManagersSearchDropdown;
