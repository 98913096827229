import { Button, ButtonProps } from "primereact/button";
import { ReactElement } from "react";

export default function ButtonBase(props: ButtonProps): ReactElement<Button> {
  const combinedClassName = `p-button p-button-raised p-button-outlined ${
    props.className ?? ""
  }`;

  return <Button {...props} className={combinedClassName} />;
}
